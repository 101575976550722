import { useEffect, useState } from "react";
import "./counterparties-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as ArrowBack } from "../../../../shared/images/svg/arrow-back.svg";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { API } from "../../../../consts/api";
import { SelectItem } from "../../../../core/view-models/select-item";
import { UpdateObject } from "../../../../shared/helpers/update-object";
import Selected from "../../../../components/selected-component/selected-component";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import { useLocalStorage } from "../../../../shared/hooks/localStorage";
import { VariablesLocalStorage } from "../../../../consts/variables-localstorage";
import { LocalStorageListenner } from "../../../../shared/helpers/localstorage-listener";

const counterpartiesCardCN = bemCN("counterparties-card");

export const CounterpartiesCard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [counterparties, setCounterparties] = useState(
    location.state && location.state.counterparties
      ? location.state.counterparties
      : {
          id: 0,
          dateTime: "",
          name: "",
          nameShort: "",
          legalFormId: 0,
          contractorTypeId: 0,
          contractorType: "",
          inn: "",
          kpp: "",
          ogrn: "",
          addressLegal: "",
          addressActual: "",
          addressMail: "",
          director: "",
          accountant: "",
          phone: "",
          email: "",
          contractSignature: "",
          contractPosition: "",
          contractBasis: "",
          bik: "",
          bankId: 0,
          bankCityId: 0,
          checkAccount: "",
          corAccount: "",
          stores: "",
          storesId: [],
        }
  );

  const [IsNew, SetIsNew] = useState(
    location.state && location.state.counterparties ? false : true
  );
  const [isShowModalDeleteCount, setIsShowModalDeleteCountr] = useState(false);
  const [isShowModalUpdateCount, setIsShowModalUpdateCountr] = useState(false);

  const [IsEdit, SetIsEdit] = useState(IsNew);
  const [legalForm, setLefalForm] = useState<any[]>([]);
  const [banks, setBanks] = useState<any[]>([]);
  const [contractorTypes, setContractorType] = useState<any[]>([]);
  const [deleteIdStocks, setDeleteIdStocks] = useState<number[]>([]);
  const [cities, setCities] = useState<any[]>([]);
  const [stocks, setStocks] = useState<any[]>([]);
  const [isUpdate, SetIsUpdate] = useLocalStorage(
    VariablesLocalStorage.IS_UPDATE_CONTRACTORS,
    false
  );
  const [isStocksLoading, SetIsStocksLoading] = useState(true);
  const NavHome = () => {
    navigate("/home");
  };
  //Подписка на событие изменения складов
  useEffect(() => {
    LocalStorageListenner(
      VariablesLocalStorage.IS_UPDATE_STOCK,
      SetIsStocksLoading
    );
  }, []);

  //Реакция на изменения складов
  useEffect(() => {
    if (isStocksLoading && !IsNew) {
      API.contractorDetail(counterparties.id).then((res) => {
        setCounterparties({ ...counterparties, storesId: res.data.storesId });
        SetIsStocksLoading(false);
      });
      API.storeGetAllItemsList().then((res) => {
        setStocks([...res.data.items]);
      });
    }
  }, [isStocksLoading]);

  //Загрузка доп. информации
  useEffect(() => {
    API.legalFormGetAllItemsList()
      .then((res) => {
        setLefalForm([...res.data.items]);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.contractorTypeGetAllItemsList()
      .then((res) => {
        setContractorType([...res.data.items]);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.bankGetAllItemsList()
      .then((res) => {
        
        setBanks([...res.data]);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.bankCityGetAllItemsList()
      .then((res) => {
        setCities([...res.data]);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.storeGetAllItemsList()
      .then((res) => {
        setStocks([...res.data.items]);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, []);

  //Обработка клика на удаление склада
  const deleteStock = (id: number) => {
    deleteIdStocks.push(id);
    setDeleteIdStocks(deleteIdStocks);
    let stockId = counterparties.storesId.filter((item: number) => item !== id);
    setCounterparties({
      ...counterparties,
      storesId: stockId,
    });
  };

  //Удаление контрагента
  const deleteCounterparties = () => {
    setIsShowModalDeleteCountr(false);
    API.contractorDelete(counterparties.id).then(() =>
      navigate("/directories/counterparties")
    );
  };

  //Изменение или добавление контрагента
  const updateCounterparties = () => {
    setIsShowModalUpdateCountr(false);
    if (IsNew) {
      API.contractorAddCreate(counterparties).then(() => {
        SetIsEdit(false);
        SetIsNew(false);
        SetIsUpdate(true);
      });
    } else {
      API.contractorUpdateUpdate(counterparties).then(() => {
        SetIsEdit(false);
        SetIsNew(false);
      });
      deleteIdStocks.map((id) => {
        API.storeDelete(id);
      });
    }
  };

  return (
    <div className={counterpartiesCardCN()}>
      <div className={counterpartiesCardCN("header")}>
        <div>
          <button
            onClick={() => {
              counterparties.name && IsEdit
                ? SetIsEdit(false)
                : navigate("/directories/counterparties");
            }}
          >
            <ArrowBack />
          </button>
          <h2>
            {!IsNew ? counterparties.nameShort : "Добавление контрагента"}
          </h2>
        </div>
        <div>
          <span className="h2">Петров В.В.</span>
        </div>
      </div>
      <div className={counterpartiesCardCN(null, ["h3"])}>
        Наименование и юридические реквизиты
      </div>
      <div className={counterpartiesCardCN("info")}>
        <div>
          <InputText
            isRequired
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "nameShort", v));
            }}
            header="Краткое название:"
            text={counterparties ? counterparties.nameShort : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            isRequired
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "name", v));
            }}
            header="Полное название:"
            text={counterparties ? counterparties.name : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          {counterparties && contractorTypes.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Тип контрагента:"
              text={
                counterparties.contractorTypeId
                  ? contractorTypes.find(
                      (ct) => ct.id === counterparties.contractorTypeId
                    ).name
                  : ""
              }
              isActive
            />
          ) : (
            <div className={counterpartiesCardCN("popap")}>
              <p className="h4">
                <span className={counterpartiesCardCN("required")}>* </span> Тип
                контрагента:
              </p>
              <Selected
                OnChange={(v) => {
                  setCounterparties(
                    UpdateObject(counterparties, "contractorTypeId", v)
                  );
                  setCounterparties(
                    UpdateObject(
                      counterparties,
                      "contractorType",
                      contractorTypes.find((r) => r.id === v).name
                    )
                  );
                }}
                active={counterparties.contractorTypeId}
                items={contractorTypes.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          {counterparties && legalForm.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Правовая форма:"
              text={
                counterparties && legalForm.length > 0
                  ? legalForm.find((lf) => lf.id === counterparties.legalFormId)
                      .name
                  : ""
              }
              isActive
            />
          ) : (
            <div className={counterpartiesCardCN("popap")}>
              <p className="h4">
                <span className={counterpartiesCardCN("required")}>* </span>
                Правовая форма
              </p>
              <Selected
                OnChange={(v) => {
                  setCounterparties(
                    UpdateObject(counterparties, "legalFormId", v)
                  );
                }}
                active={
                  counterparties?.legalFormId ? counterparties?.legalFormId : 0
                }
                items={legalForm.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          <InputText
            isDigitOnly
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "inn", v));
            }}
            maxLengh={10}
            header="ИНН:"
            text={counterparties ? counterparties.inn : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            isDigitOnly
            maxLengh={9}
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "kpp", v));
            }}
            header="КПП:"
            text={counterparties ? counterparties.kpp : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            isDigitOnly
            maxLengh={13}
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "ogrn", v));
            }}
            header="ОГРН:"
            text={counterparties ? counterparties.ogrn : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>
      <div className={counterpartiesCardCN(null, ["h3"])}>Контакты</div>
      <div className={counterpartiesCardCN("info")}>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(
                UpdateObject(counterparties, "addressLegal", v)
              );
            }}
            header="Юридический адрес:"
            text={counterparties ? counterparties.addressLegal : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Фактический адрес:"
            OnChange={(v) => {
              setCounterparties(
                UpdateObject(counterparties, "addressActual", v)
              );
            }}
            text={counterparties ? counterparties.addressActual : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "addressMail", v));
            }}
            header="Почтовый адрес:"
            text={counterparties ? counterparties.addressMail : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "director", v));
            }}
            header="Директор:"
            text={counterparties ? counterparties.director : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "accountant", v));
            }}
            header="Бухгалтер:"
            text={counterparties ? counterparties.accountant : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "phone", v));
            }}
            header="Телефон:"
            text={counterparties ? counterparties.phone : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "email", v));
            }}
            header="E-mail:"
            text={counterparties ? counterparties.email : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>
      <div className={counterpartiesCardCN(null, ["h3"])}>Договор</div>
      <div className={counterpartiesCardCN("info-3")}>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(
                UpdateObject(counterparties, "contractSignature", v)
              );
            }}
            header="Кто подписывает договор:"
            text={counterparties ? counterparties.contractSignature : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(
                UpdateObject(counterparties, "contractPostion", v)
              );
            }}
            header="Должность лица:"
            text={counterparties ? counterparties.contractPostion : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              setCounterparties(
                UpdateObject(counterparties, "contractBasis", v)
              );
            }}
            header="Основание:"
            text={counterparties ? counterparties.contractBasis : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>
      <div className={counterpartiesCardCN(null, ["h3"])}>Расчетные счета</div>
      <div className={counterpartiesCardCN("info-3")}>
        <div>
          <InputText
            isDigitOnly
            maxLengh={9}
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "bik", v));
            }}
            header="БИК:"
            text={counterparties ? counterparties.bik : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          {counterparties && banks.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Банк:"
              text={
                counterparties.bankId
                  ? banks.find((b) => b.id === counterparties.bankId).name
                  : ""
              }
              isActive
            />
          ) : (
            <div className={counterpartiesCardCN("popap")}>
              <p className="h4">
                <span className={counterpartiesCardCN("required")}>* </span>
                Банк:
              </p>
              <Selected
                OnChange={(v) => {
                  setCounterparties(UpdateObject(counterparties, "bankId", v));
                }}
                active={counterparties.bankId}
                items={banks.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          {counterparties && cities.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Город банка:"
              text={
                counterparties.bankCityId
                  ? cities.find((c) => c.id === counterparties.bankCityId).name
                  : ""
              }
              isActive
            />
          ) : (
            <div className={counterpartiesCardCN("popap")}>
              <p className="h4">
                <span className={counterpartiesCardCN("required")}>* </span>
                Город банка:
              </p>
              <Selected
                OnChange={(v) => {
                  setCounterparties(
                    UpdateObject(counterparties, "bankCityId", v)
                  );
                }}
                active={counterparties.bankCityId}
                items={cities.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          <InputText
            isDigitOnly
            maxLengh={20}
            OnChange={(v) => {
              setCounterparties(
                UpdateObject(counterparties, "checkAccount", v)
              );
            }}
            header="Расчетный счет:"
            text={counterparties ? counterparties.checkAccount : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            isDigitOnly
            maxLengh={20}
            OnChange={(v) => {
              setCounterparties(UpdateObject(counterparties, "corAccount", v));
            }}
            header="Кор. счет:"
            text={counterparties ? counterparties.corAccount : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>
      <div className={counterpartiesCardCN(null, ["h3"])}>Склады</div>
      <div className={counterpartiesCardCN("stocks")}>
        {counterparties &&
          counterparties.storesId.map((stockId: number, index: number) => {
            return (
              <div key={stockId} className={counterpartiesCardCN("stock-item")}>
                {stocks.length > 0 && (
                  <>
                    <InputText
                      isActive={!IsEdit}
                      header="Название"
                      text={
                        stocks.find((st) => st.id === stockId)
                          ? stocks.find((st) => st.id === stockId).name
                          : ""
                      }
                    />
                    <InputText
                      isActive={true}
                      header="Адрес"
                      text={
                        stocks.find((st) => st.id === stockId)
                          ? stocks.find((st) => st.id === stockId).address
                          : ""
                      }
                    />
                  </>
                )}

                {IsEdit && (
                  <div className={counterpartiesCardCN("trashbin")}>
                    <Button
                      onClick={() => deleteStock(stockId)}
                      image-white
                      icon={<TrashBin />}
                    />
                  </div>
                )}
              </div>
            );
          })}
        {IsEdit ? (
          <div className={counterpartiesCardCN("add-stock-btn")}>
            {!IsNew ? (
              <a
                className={counterpartiesCardCN(null, ["h4"])}
                target="_blank"
                rel="noopener"
                href="/directories/stocks/card/new"
              >
                <Plus />
                Добавить склад
              </a>
            ) : (
              <p className={counterpartiesCardCN("disabled", ["h4"])}>
                *Добавление нового склада доступно после создания контрагента
              </p>
            )}
          </div>
        ) : null}
      </div>

      <div className={counterpartiesCardCN("buttons")}>
        <Button
          onClick={() => {
            if (counterparties.name && IsEdit) {
              SetIsEdit(false);
              API.contractorDetail(counterparties.id).then((res) => {
                setCounterparties(res.data);
              });
            } else {
              navigate("/directories/counterparties");
            }
          }}
          text="Закрыть"
          white-outline
        />
        {IsEdit ? (
          <Button
            onClick={() =>
              !IsNew ? setIsShowModalUpdateCountr(true) : updateCounterparties()
            }
            text={"Сохранить"}
            blue
          />
        ) : (
          <Button
            onClick={() => {
              SetIsEdit(true);
            }}
            text={"Редактировать"}
            blue
          />
        )}
        {counterparties
          ? IsEdit &&
            !IsNew && (
              <Button
                image-red
                icon={<TrashBin />}
                onClick={() => setIsShowModalDeleteCountr(true)}
              />
            )
          : null}
      </div>
      <ConfirmationModal
        onClickAgree={deleteCounterparties}
        isShowDialog={isShowModalDeleteCount}
        setIsShowDialog={setIsShowModalDeleteCountr}
        text={
          'Вы уверены, что хотите удалить контрагента с названием "' +
          counterparties?.name +
          '" и все его склады?'
        }
      />
      <ConfirmationModal
        onClickAgree={updateCounterparties}
        isShowDialog={isShowModalUpdateCount}
        setIsShowDialog={setIsShowModalUpdateCountr}
        text={
          "Вы уверены, что хотите изменить контрагента с названием " +
          counterparties?.name +
          "?"
        }
      />
    </div>
  );
};
