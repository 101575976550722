import Calendar from "../../../../components/calendar-component/calendar-component";
import NoticeCard from "../../../../components/notice-card-component/notice-card-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Table from "../../../../components/table-component/table-component";
import { bemCN } from "../../../../configs/bem-classname";
import { data } from "../../../../consts/values/bar-chart-data";
import { BarChartDataItem } from "../../../../core/view-models/bar-chart-data-item";
import { CustomBarChartColors } from "../../../../ui/bar-chart-colors/bar-chart-colors";
import { CustomBarChart as BarChart } from "../../../../ui/bar-chart/bar-chart";
import CardChart from "../../../../ui/card-chart/card-chart";
import "./main-component.scss";
import { useEffect, useState } from "react";
import {
  MarginalityModel,
  StatisticsItemModel,
} from "../../../../core/api/data-contracts";
import { API } from "../../../../consts/api";
import { link } from "fs";
import { useNavigate } from "react-router-dom";

const mainCN = bemCN("main");
const dataColor = [
  new BarChartDataItem("Заказали", 16),
  new BarChartDataItem("Возвраты", 45),
  new BarChartDataItem("Выкупили", 100),
  new BarChartDataItem("Отзывы", 55),
];
export const MainComponent = () => {
  const [purchaseAgregate, setPurchaseAgregate] =
    useState<StatisticsItemModel[]>();
  const navigate = useNavigate();
  const [orderAgregate, setOrderAgregate] = useState<StatisticsItemModel[]>();
  const [chart, setChart] = useState<StatisticsItemModel[]>();
  const [chartOrbr, setChartOrbr] = useState<StatisticsItemModel[]>();
  const [goods, setGoods] = useState<MarginalityModel[]>();
  const [headers, setHeaders] = useState<any[]>();
  const [date, setDate] = useState<any[]>();
  const [profit, setProfit] = useState<StatisticsItemModel[]>();

  const NavHome = () => {
    navigate("/home");
  };
  useEffect(() => {
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;

    API.statisticsGetPurchaseStatisticsList({ start, end })
      .then((res) => {
        setPurchaseAgregate(res.data);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.statisticsGetOrderStatisticsList({ start, end })
      .then((res) => {
        setOrderAgregate(res.data);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.statisticsGetRevenueStatisticsList({ start, end })
      .then((res) => {
        setChart(res.data);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.statisticsGetOrbrStatisticsList({ start, end })
      .then((res) => {
        setChartOrbr(res.data);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.statisticsGetTableMarginalityStatisticsList({ start, end })
      .then((res) => {
        setGoods(res.data);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.statisticsGetProfitAndRevenueStatisticsList({ start, end })
      .then((res) => {
        setProfit(res.data);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    if (!headers) {
      API.statisticsGetNameFieldsMarginalityList()
        .then((res) => setHeaders(res.data as any))
        .catch((e) => {
          if (e.request.status == 401) {
            NavHome();
          }
        });
    }
  }, [date]);

  const onClickSort = (header: string, isDescending: boolean) => {
    goods &&
      API.statisticsSortTableMarginalityStatisticsCreate(goods, {
        header,
        isDescending,
      }).then((res) => {
        setGoods(res.data);
      });
  };
  return (
    <div className={mainCN()}>
      <div className={mainCN("topPanel")}>
        {/* <RadioCalendar /> */}
        <Calendar ChangeDate={setDate} />
      </div>

      <div className={mainCN("notices")}>
        {/* <NoticeCard title={"Задачи"} items={noticeItems} link={""} /> */}
        <NoticeCard
          link="/sales/orders"
          title={"Заказы"}
          items={orderAgregate}
          links={[
            () => {
              navigate("/sales/orders", {
                replace: true,
                state: {
                  IsNew: true,
                },
              });
            },
            () => {
              navigate("/sales/orders", {
                replace: true,
                state: {
                  filter: "В доставке",
                },
              });
            },
            () => {
              navigate("/sales/orders", {
                replace: true,
                state: {
                  filter: "Отмена",
                },
              });
            },
            () => {
              navigate("/sales/orders", {
                replace: true,
                state: {
                  filter: "Продано",
                },
              });
            },
          ]}
        />
        <NoticeCard
          title={"Закупки"}
          items={purchaseAgregate}
          link="/procurement/purchase"
          links={[
            () => {
              navigate("/procurement/purchase", {
                replace: true,
                state: {
                  filter: "Создано",
                },
              });
            },
            () => {
              navigate("/procurement/purchase", {
                replace: true,
                state: {
                  filter: "Ожидает оплаты",
                },
              });
            },
            () => {
              navigate("/procurement/purchase", {
                replace: true,
                state: {
                  filter: "Оплачено",
                },
              });
            },
            () => {
              navigate("/procurement/purchase", {
                replace: true,
                state: {
                  filter: "Отгружено",
                },
              });
            },
          ]}
        />
        <div className={mainCN("cards")}>
          <CardChart
            blue
            data={data}
            header={profit && profit[0].name ? profit[0].name : ""}
            unit="руб"
            value={profit && profit[0].value ? profit[0].value.toString() : ""}
            isLeft
          />
          <CardChart
            green
            data={data}
            header={profit && profit[1].name ? profit[1].name : ""}
            unit="руб"
            value={profit && profit[1].value ? profit[1].value.toString() : ""}
          />
        </div>
      </div>
      <div className={mainCN("graphs")}>
        <div className={"h3"}>Динамика</div>
        <div>
          <BarChart green labelY="Выручка" data={chart} />
          <BarChart blue labelY="Заказы" data={chart} isCount />
          <CustomBarChartColors blue data={chartOrbr} />
        </div>
      </div>
      <div className={mainCN("table")}>
        <div className={"h3"}>Маржинальность товаров</div>
        <Table
          onClickSort={onClickSort}
          dataGenerate={goods}
          headersGenerate={headers}
          isSortable
          isClickable
          onClickRow={(index) => {
            console.log(index);
            goods && navigate("/directories/goods/card/" + goods[index].goodId);
          }}
        />
      </div>
    </div>
  );
};
