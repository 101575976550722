import React, { InputHTMLAttributes, ChangeEvent, FC, useState } from "react";
import "./InputFieldPhone.scss";
import { validateField, ValidatorFunc } from "../../shared/helpers/validation";
import { bemCN } from "../../configs/bem-classname";
import InputMask from "react-input-mask";
import { setActiveClass, setClassName } from "../../shared/helpers/className";

const inputFieldPhoneCn = bemCN("input-field-phone");

const getPhoneCode = (phone: string) => {
  return phone.slice(0, phone.indexOf(" (")).replaceAll("\\", "");
};

interface InputFieldPhoneProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  classNameInput?: string;
  validators?: ValidatorFunc[];
}
interface Country {
  name: string;
  code: string;
  flagImg: string;
  phoneMask: string;
}

const countries: Country[] = [
  {
    name: "Россия",
    code: "RU",
    flagImg: require("../../shared/images/countries/ru.png"),
    phoneMask: "+7 (999) 999-99-99",
  },
  {
    name: "Беларусь",
    code: "BY",
    flagImg: require("../../shared/images/countries/by.png"),
    phoneMask: "+375 (99) 999-99-99",
  },
  // {
  //   name: 'Украина',
  //   code: 'UA',
  //   flagImg: require('../../../../shared/image/countries/ua.png'),
  //   phoneMask: '+380 (99) 999-99-99',
  // },
  {
    name: "Армения",
    code: "AM",
    flagImg: require("../../shared/images/countries/am.png"),
    phoneMask: "+374 (99) 999-99-99",
  },
  {
    name: "Кыргызстан",
    code: "KG",
    flagImg: require("../../shared/images/countries/kg.png"),
    phoneMask: "+\\9\\96 (99) 999-99-99",
  },
  {
    name: "Казахстан",
    code: "KZ",
    flagImg: require("../../shared/images/countries/kz.png"),
    phoneMask: "+7 (999) 999-99-99",
  },
  {
    name: "Узбекистан",
    code: "UZ",
    flagImg: require("../../shared/images/countries/uz.png"),
    phoneMask: "+\\9\\98 (99) 999-99-99",
  },
  {
    name: "Китай",
    code: "CN",
    flagImg: require("../../shared/images/countries/cn.png"),
    phoneMask: "+86 (99) 999-99-99",
  },
  {
    name: "Гонконг",
    code: "HK",
    flagImg: require("../../shared/images/countries/hk.png"),
    phoneMask: "+852 (99) 999-99-99",
  },
  {
    name: "Макао",
    code: "MO",
    flagImg: require("../../shared/images/countries/mo.png"),
    phoneMask: "+853 (99) 999-99-99",
  },
  // {
  //   name: 'Молдавия',
  //   code: 'MD',
  //   flagImg: require('../../../../shared/image/countries/md.png'),
  //   phoneMask: '+373 (99) 999-99-99',
  // },
  {
    name: "Турция",
    code: "TR",
    flagImg: require("../../shared/images/countries/tr.png"),
    phoneMask: "+\\90 (99) 999-99-99",
  },
  // {
  //   name: 'Израиль',
  //   code: 'IL',
  //   flagImg: require('../../../../shared/image/countries/il.png'),
  //   phoneMask: '+\\972 (99) 999-99-99',
  // },
  // {
  //   name: 'Азербайджан',
  //   code: 'AZ',
  //   flagImg: require('../../../../shared/image/countries/az.png'),
  //   phoneMask: '+\\9\\94 (99) 999-99-99',
  // }
];

const russia = countries.find((c) => c.code === "RU")!;

const InputFieldPhone: FC<InputFieldPhoneProps> = ({
  className,
  classNameInput,
  label,
  onInput,
  validators,
  ...rest
}) => {
  const [haveValue, setHaveValue] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [active, setActive] = useState<boolean>(false);
  const [country, setCountry] = useState<Country>(russia); //todo: detect by location in browser

  const validate = (v: any) => {
    if (validators?.length) {
      setError(validateField(v, validators));
    }
  };

  const inputUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof onInput === "function") onInput(e);

    validate(e.target.value);
    if (!e.target.value) {
      setHaveValue(false);
      return;
    }
    setHaveValue(true);
  };

  const itemClick = (item: Country) => {
    setActive(false);
    setCountry(item);
  };
  return (
    <label className={inputFieldPhoneCn("", [haveValue ? "active" : ""])}>
      <fieldset className={inputFieldPhoneCn("fieldset")}>
        <div className={inputFieldPhoneCn("select")}>
          <div
            className={inputFieldPhoneCn("selected-value")}
            onClick={() => setActive((prev) => !prev)}
          >
            <img
              src={country?.flagImg}
              className={inputFieldPhoneCn("selected-flag-img")}
              alt={country?.code + "-flag-icon"}
              title={country?.name}
            />
            {/*<span>{country?.name}</span>*/}
            <svg
              className={inputFieldPhoneCn("flag-arrow")}
              width=".75rem"
              height=".5rem"
              viewBox="0 0 12 8"
              fill="#FFFFFF"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M6 8L11.1962 0.5L0.803848 0.5L6 8Z" fill="9222222" />
            </svg>
          </div>
          <ul className={inputFieldPhoneCn("flag-list")}>
            {countries?.map((item) => (
              <li
                className={inputFieldPhoneCn("item", {
                  active: item.code === country?.code,
                })}
                key={item.code}
                onClick={() => itemClick(item)}
              >
                <img
                  className={inputFieldPhoneCn("flag-img")}
                  src={item.flagImg}
                  alt={country?.code + "-flag-icon"}
                />
                <span>{item.name}</span>
                <span>{getPhoneCode(item.phoneMask)}</span>
                {/* {item.code === country?.code && (
                  // <Icon check_mark className={inputFieldPhoneCn('check-icon')} />
                )} */}
              </li>
            ))}
          </ul>
        </div>
        <InputMask
          className={inputFieldPhoneCn("input")}
          mask={country?.phoneMask || "+7 (999) 999-99-99"}
          maskChar="_"
          id="phone"
          onInput={inputUpdate}
          {...rest}
        />
        <legend className={inputFieldPhoneCn("legend")}>{label}</legend>
        <span className={inputFieldPhoneCn("name")}>{label}</span>
      </fieldset>
      {error && (
        <span className={inputFieldPhoneCn("error_text")}>{error}</span>
      )}
    </label>
  );
};

export default InputFieldPhone;
