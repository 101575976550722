import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import "./counterparties-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { FC, useEffect, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../consts/api";

type counterpartiesProps = {
  id?: string;
};

export const Counterparties: FC<counterpartiesProps> = ({ id }) => {
  const counterpartiesCN = bemCN("counterparties");

  const navigate = useNavigate();
  const onClickRow = (id: number) => {
    navigate("card/" + id, {
      replace: true,
      state: { counterparties: CounterpartiesList.find((e) => e.id === id) },
    });
  };

  const onClickAdd = () => {
    navigate("card/new");
  };
  const NavHome = () => {
    navigate("/home");
  };
  const [CounterpartiesList, SetCounterpartiesList] = useState<any[]>([]);
  const [Headers, SetHeaders] = useState<any>();
  const [finder, setFinder] = useState<string | undefined>();

  useEffect(() => {
    API.contractorGetAllItemsList({ finder }).then((res) => {
      if (!Headers) SetHeaders(res.data.nameFields);
      SetCounterpartiesList([...res.data.items]);
    }).catch((e) => {
      if (e.request.status == 401) {
        NavHome();
      }
    });
  }, [finder]);

  const onClickSort = (header: string, isDescending: boolean) => {
    CounterpartiesList &&
      API.contractorSortTableContractorCreate(CounterpartiesList, {
        header,
        isDescending,
      }).then((res) => {
        SetCounterpartiesList(res.data);
      }).catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
  };

  return (
    <div id={id} className={counterpartiesCN()}>
      {/* <div className={counterpartiesCN("topPanel")}>
        <RadioCalendar />
        <Calendar />
      </div> */}
      <div className={counterpartiesCN("title", ["h1"])}>Контрагенты</div>
      <div className={counterpartiesCN("buttons")}>
        <Button
          text-image-left
          text="Добавить контрагента"
          onClick={onClickAdd}
          icon={<PlusCircle />}
        />
        {/* <ExportButton></ExportButton> */}
        <InputText
          placeholder="Поиск по контрагентам"
          icon={<Search />}
          OnChange={setFinder}
          icon-left
        />
      </div>
      <Table
        isSortable
        onClickSort={onClickSort}
        dataGenerate={CounterpartiesList}
        headersGenerate={Headers}
        onClickRow={onClickRow}
      />
    </div>
  );
};
