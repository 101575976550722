/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { NotificationPaymentRequest, OrderHistoryDto, SellableItem } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Payment<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Payment
   * @name SellableItemsList
   * @request GET:/Payment/SellableItems
   */
  sellableItemsList = (params: RequestParams = {}) =>
    this.request<SellableItem[], any>({
      path: `/Payment/SellableItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Payment
   * @name OrdersList
   * @request GET:/Payment/Orders
   */
  ordersList = (params: RequestParams = {}) =>
    this.request<OrderHistoryDto[], any>({
      path: `/Payment/Orders`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Payment
   * @name InitCreate
   * @request POST:/Payment/Init
   */
  initCreate = (
    query?: {
      /** @format int32 */
      itemId?: number;
      /** @default false */
      recurrent?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/Payment/Init`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Payment
   * @name NotifyCreate
   * @request POST:/Payment/Notify
   */
  notifyCreate = (data: NotificationPaymentRequest, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/Payment/Notify`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
}
