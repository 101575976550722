import { Form, Link, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { API } from '../../consts/api';


const ExitForm = () => {
  const navigate = useNavigate();
 useEffect(()=>{
  API.authLogoutList().then(()=>{
    navigate("/home");
  }).catch(()=>{
    navigate("/home");
  });
},[]);


  return null;
};

export default ExitForm;


