import { FC, useEffect, useMemo, useState } from "react";
import "./profile-key.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ApiKeyItem } from "../../../../consts/apiKey-item";
import React from "react";
import Loading from "../../../../components/loading-component/loading-component";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import { API } from "../../../../consts/api";
import { useNavigate } from "react-router-dom";

interface ProfileKeyProps {}

const profileKeyCN = bemCN("profileKey");

const ProfileKey: FC<ProfileKeyProps> = () => {
  const [keys, setKeys] = useState<ApiKeyItem[]>([]);
  const [isLoad, setIsLoad] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [activeKeyDelete, setActiveKeyDelete] = useState(-1);
  const [isShowError, setIsShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const NavHome = () => {
    navigate("/home");
  };
  useEffect(() => {
    API.authGetApiKeyKindsList()
      .then((res: any) => {
        setKeys(res.data);
        setIsLoad(true);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, [1]);

  const addKey = (key: ApiKeyItem) => {
    setIsShowError(false);
    setIsLoad(false);
    API.authSetApiKeyUpdate(key)
      .then((res) => {
        setIsLoad(true);
      })
      .catch(() => {
        setIsLoad(true);
        setErrorMessage("Некорректное значение ключа.");
        setIsShowError(true);
      });
  };

  const changeKey = (value: string, id: number) => {
    keys?.map((k) => {
      if (k.id == id) {
        k.value = value;
      }
    });
  };

  if (!isLoad) return <Loading color="dark" />;
  return (
    <div className={profileKeyCN()}>
      {keys?.map((k) => (
        <div key={k.id} className={profileKeyCN("add-key-block")}>
          <input
            id="nameKeyInput"
            readOnly
            placeholder="Название"
            value={k.name}
          />
          <input
            id="tokenKeyInput"
            placeholder="Введите API-ключ"
            value={k.value}
            onChange={(e) => {
              changeKey(e.target.value, k.id);
            }}
          ></input>
          <button onClick={() => addKey(k)}>Подключить</button>
          <div
            onClick={() => window.open("/instructions", "_blank")}
            data-title="Скопированный на предыдущем шаге. (Нажмите, чтобы открыть инструкукцию для предыдущего шага)"
            className={profileKeyCN("helper")}
          >
            ?
          </div>
        </div>
      ))}

      {isShowError ? (
        <div className={profileKeyCN("error-message")}>{errorMessage}</div>
      ) : null}
    </div>
  );
};

export default ProfileKey;
