import "./notice-card-component.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC } from "react";
import { ReactComponent as Arrow } from "../../shared/images/svg/arrow-right.svg";
import { NoticeItem } from "../../core/view-models/notice-item";
import { StatisticsItemModel } from "../../core/api/data-contracts";

type NoticeCardProps = {
  id?: string;
  title: string;
  items?: StatisticsItemModel[];
  links?: any[];
  link: string;
};

const NoticeCard: FC<NoticeCardProps> = ({ id, title, items, link, links }) => {
  const noticeCardCN = bemCN("notice-card");

  return (
    <div className={noticeCardCN()}>
      <a href={link} className={noticeCardCN("title", ["h3"])}>
        {title} <Arrow />
      </a>
      <div className={noticeCardCN("card")}>
        {items?.map((item, index: number) => (
          <div key={index} className={noticeCardCN("row")}>
            <div
              className="h4"
              onClick={() => {
                links && links[index]();
              }}
            >
              {item.name}
              {/* {item.type === "Новые" ? (<div className={noticeCardCN("cirle")}></div>) : null} */}
            </div>
            <div>
              <div className="h4"> {item.count}</div>
              <div className="h5">на {item.value} ₽</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NoticeCard;
