import Popup from "../../../../ui/popup/popup";
import { RadioGroup } from "../../../../components/radio-group-component/radio-group-component";
import "./marketplace-barcode-popup-component.scss";
import { ReactComponent as Barcode } from "../../../../shared/images/svg/barcode.svg";
import { FC } from "react";
import { bemCN } from "../../../../configs/bem-classname";
import { SelectItem } from "../../../../core/view-models/select-item";

type MarketplaceBarcodePopupProps = {
  id?: string;
  onCheck?(): void;
  isShow: boolean;
  setIsShow(isShow: boolean): void;
  setCurrentMarketplace(currentDate: number): void;
  items: string[];
  active: number;
};

const MarketplaceBarcodePopup: FC<MarketplaceBarcodePopupProps> = ({
  id,
  onCheck,
  isShow,
  setIsShow,
  setCurrentMarketplace,
  items,
  active,
}) => {
  const radioCalendarCardCN = bemCN("marketplace-barcode-popup");
  return (
    <Popup key={"radio-calendar"} isShow={isShow} setIsShow={setIsShow}>
      <div className={radioCalendarCardCN()}>
        <RadioGroup
          active={active}
          double-image
          icons={[<Barcode />]}
          items={items.map((item, index) => {
            return new SelectItem(index, item);
          })}
          onChange={setCurrentMarketplace}
        />
      </div>
    </Popup>
  );
};

export default MarketplaceBarcodePopup;
