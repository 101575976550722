interface ICookieStorage {
  getCookie(name: string): string | undefined;
  possibleCookieChanged(): boolean;
}

type CookieChangedSubscriber = () => void;

class CookieStorage implements ICookieStorage {
  private currentCookie: string;
  private subscribers: CookieChangedSubscriber[] = [];

  constructor() {
    this.currentCookie = document.cookie;
  }
  getCookie(name: string): string | undefined {
    const cookiesRegExp = new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)',
    );
    const matches = cookiesRegExp.exec(this.currentCookie);
    return matches ? matches[1] : undefined;
  }

  possibleCookieChanged(): boolean {
    if (this.currentCookie !== document.cookie) {
      this.currentCookie = document.cookie;
      this.emitCookieChanged();
      return true;
    }

    return false;
  }

  onCookieChanged(cb: CookieChangedSubscriber) {
    this.subscribers.push(cb);
  }

  emitCookieChanged() {
    this.subscribers.forEach((cb) => cb());
  }
}

export const cookieStorage = new CookieStorage();
