import React, { useEffect, useState } from "react";
import { API } from "../../consts/api";

export interface AuthContextI {
  isAuth: boolean;
  token?: string;

  onLogin?(email: string, password: string): Promise<string | null>;

  onLogout?(): void;

  onRegistration?(
    email: string,
    password: string,
    phone: string,
    name: string,
    gender: number,
    birthday: string,
    city: string,
    country: string,
    idRole: number
  ): Promise<boolean>;
}

type Props = {
  children: React.ReactNode;
};

export const AuthContext = React.createContext<AuthContextI>({
  token: undefined,
  isAuth: false,
});

const AuthProvider = ({ children }: Props) => {
  // const [token, setToken] = useState<string>();
  const [auth, setAuth] = useState<boolean>(false);

  const handleLogin = async (email: string, passwordHash: string) => {
    let res = null;
    await API.authLoginCreate({ email, passwordHash }).then((r) => {
      if (!r.data.accessToken) return;
      // setToken(r.data.accessToken);
      res = r.data.accessToken;
      // console.log(r.headers);

      localStorage.setItem("auth", res);
    }).catch(()=>{
      res = "error";
    });
    return res;
  };

  const isAuth = (): void => {
    API.authGetUserIdList()
      .then(() => {
        setAuth(true);
        // console.log("ok");
      })
      .catch((e) => {
        setAuth(false);
        console.log(e);
      });
  };

  const handleRegistration = async (
    email: string,
    passwordHash: string,
    phoneNumber: string,
    name: string,
    gender: number,
    birthday: string,
    city: string,
    country: string,
    jobTitle: number
  ): Promise<boolean> => {
    let result = false;
    await API.authRegistrationCreate({
      email,
      passwordHash,
      phoneNumber,
      name,
      gender,
      birthday,
      city,
      country,
      jobTitle,
    })
      .then((res) => {
        if (res.status == 200) {
          result = true;
        } else {
          result = false;
        }
      })
      .catch((e) => {
        result = false;
      });
    return result;
  };
  useEffect(() => {
    isAuth();
  }, []);


  const value: AuthContextI = {
    isAuth: auth,
    onLogin: handleLogin,
    onRegistration: handleRegistration,
  };

  useEffect(() => {
    isAuth();
  }, []);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
