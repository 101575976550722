import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import "./stocks-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { FC, useEffect, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../consts/api";
import Loading from "../../../../components/loading-component/loading-component";

type StockProps = {
  id?: string;
};

export const Stocks: FC<StockProps> = ({ id }) => {
  const stocksCN = bemCN("stocks");
  const navigate = useNavigate();
  const [StocksList, SetStocksList] = useState<any[]>([]);
  const [Headers, SetHeaders] = useState<any>();
  const [IsLoading, SetIsLoading] = useState(false);
  const [Error, SetError] = useState<Error>();
  const [finder, setFinder] = useState<string | undefined>();
  const onClickRow = (id: number) => {
    navigate("card/" + id, {
      replace: true,
      state: { stock: StocksList.find((e) => e.id === id) },
    });
  };
  const onClickAdd = () => {
    navigate("card/new");
  };
  const NavHome = () => {
    navigate("/home");
  };
  useEffect(() => {
    API.storeGetAllItemsList({ finder })
      .then((res) => {
        if (!Headers) SetHeaders(res.data.nameFields);
        SetStocksList([...res.data.items]);
        SetIsLoading(true);
      })
      .catch((e) => {
        SetError(e);
        SetIsLoading(false);
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, [finder]);

  const onClickSort = (header: string, isDescending: boolean) => {
    StocksList &&
      API.storeSortTableStoreCreate(StocksList, {
        header,
        isDescending,
      }).then((res) => {
        SetStocksList(res.data);
      });
  };

  if (Error) {
    throw Error;
  }
  if (!IsLoading) {
    return <Loading color="dark" />;
  }
  return (
    <div id={id} className={stocksCN()}>
      {/* <div className={stocksCN("topPanel")}>
        <RadioCalendar />
        <Calendar />
      </div> */}
      <div className={stocksCN("title", ["h1"])}>Склады</div>
      <div className={stocksCN("buttons")}>
        <Button
          text-image-left
          text="Добавить склад"
          icon={<PlusCircle />}
          onClick={onClickAdd}
        />
        {/* <ExportButton></ExportButton> */}
        <InputText
          placeholder="Поиск по складам"
          OnChange={setFinder}
          icon={<Search />}
          icon-left
        />
      </div>
      <Table
        isSortable
        onClickSort={onClickSort}
        dataGenerate={StocksList}
        headersGenerate={Headers}
        onClickRow={onClickRow}
      />
    </div>
  );
};
