import { FC, useEffect, useMemo, useState } from "react";

import "./profile-tariffs.scss";
import React from "react";
import { bemCN } from "../../../../configs/bem-classname";
import { Tariff } from "../../../../core/entities/tariff";
import { PaymentHistoryItem } from "../../../../core/entities/paymentHistoryItem";
import { TableItem } from "../../../../components/table-component/table-item/table-item";
import Loading from "../../../../components/loading-component/loading-component";
import { sendMetrics } from "../../../../shared/helpers/metrics";
import ProfileInfoModalDetail from "../../../../components/profile/details-modal-form/details-modal-form";
import { Modal } from "../../../../components/modal-component/modal-component";
import Selected from "../../../../components/selected-component/selected-component";
import { SelectItem } from "../../../../core/view-models/select-item";
import { ReactComponent as CheckMark } from "../../../../shared/images/svg/check-mark-blue.svg";
import { API, APIPay } from "../../../../consts/api";
import { SellableItem } from "../../../../core/api/data-contracts";
import Table from "../../../../components/table-component/table-component";
import { TableDataRow } from "../../../../core/view-models/table-data-row";
import { TableDataItem } from "../../../../core/view-models/table-data-item";
import { TableObjectI } from "../../../../core/interfaces/table-object-i";
import { cookieStorage } from "../../../../configs/cookie-storage";
import { useNavigate } from "react-router-dom";

interface ProfileTariffsProps {}
class BodyHistory implements TableObjectI {
  row: TableDataRow;
  constructor(r: TableDataRow) {
    this.row = r;
  }
  mapToTableDataRow(): TableDataRow {
    return this.row;
  }
}

const profileTarrifsCN = bemCN("profileTariffs");

const ProfileTariffs: FC<ProfileTariffsProps> = () => {
  const [isShowTariffs, setIsShowTariffs] = useState<boolean>(false);
  const [isShowDetail, setIsShowDetail] = useState<boolean>(false);

  const [isLoad, setIsLoad] = useState(false);
  const [tariffs, setTariffs] = useState<Tariff[]>();

  const [selectTariff, setSelectTariff] = useState<Tariff>();
  const [historyPayment, setHistoryPayment] = useState<PaymentHistoryItem[]>();
  const [isAutoRenewal, setIsAutoRenewal] = useState<boolean>(true);
  const [currentTariff, setCurrentTariff] = useState<{
    name: string;
    expiryDate: string;
  }>({
    name: "",
    expiryDate: "",
  });

  let isAccess =
    cookieStorage.getCookie("IsPaidUser") &&
    cookieStorage.getCookie("IsPaidUser") == "false"
      ? false
      : true;
  const navigate = useNavigate();
  const NavHome = () => {
    navigate("/home");
  };
  useEffect(() => {
    APIPay.sellableItemsList()
      .then((res) => {
        if (res.data) {
          (res.data as any).map((r: any) => {
            r.description = r.descriptionList;
          });
          setTariffs(res.data as any as Tariff[]);
          setSelectTariff((res.data as any as Tariff[])[1]);
        }
        setIsLoad(true);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    APIPay.ordersList().then((res) => {
      setHistoryPayment(res.data as any);
    }).catch((e) => {
      if (e.request.status == 401) {
        NavHome();
      }
    });

    API.profileGetCurrentSubscriptionList().then((res: any) => {
      setCurrentTariff({
        name: res.data.name,
        expiryDate: res.data.expiryDate,
      });
    }).catch((e) => {
      if (e.request.status == 401) {
        NavHome();
      }
    });
  }, [1]);

  const onClickPayment = () => {
    selectTariff &&
      APIPay.initCreate({
        itemId: selectTariff.id,
        recurrent: isAutoRenewal,
      }).then((res: any) => {
        if (res !== -1) {
          window.open(res.data.url, "_blank");
        }
      });
  };

  // const tableHead = useMemo(
  //   () => (
  //     <React.Fragment>
  //       <th className={profileTarrifsCN("table-item")}>
  //         <div className={profileTarrifsCN("table-name-wrapper")}>
  //           <span className={profileTarrifsCN("table-name")}>Номер</span>
  //         </div>
  //       </th>
  //       <th className={profileTarrifsCN("table-item")}>
  //         <div className={profileTarrifsCN("table-name-wrapper")}>
  //           <span className={profileTarrifsCN("table-name")}>Дата</span>
  //         </div>
  //       </th>
  //       <th className={profileTarrifsCN("table-item")}>
  //         <div className={profileTarrifsCN("table-name-wrapper")}>
  //           <span className={profileTarrifsCN("table-name")}>Тариф</span>
  //         </div>
  //       </th>
  //       <th className={profileTarrifsCN("table-item")}>
  //         <div className={profileTarrifsCN("table-name-wrapper")}>
  //           <span className={profileTarrifsCN("table-name")}>Статус</span>
  //         </div>
  //       </th>
  //       <th className={profileTarrifsCN("table-item")}>
  //         <div className={profileTarrifsCN("table-name-wrapper")}>
  //           <span className={profileTarrifsCN("table-name")}>Метод оплаты</span>
  //         </div>
  //       </th>
  //       <th className={profileTarrifsCN("table-item")}>
  //         <div className={profileTarrifsCN("table-name-wrapper")}>
  //           <span className={profileTarrifsCN("table-name")}>Сумма</span>
  //         </div>
  //       </th>
  //     </React.Fragment>
  //   ),
  //   []
  // );

  const tableBody = useMemo(
    () =>
      historyPayment?.map(
        (item: PaymentHistoryItem, index: number) =>
          new BodyHistory(
            new TableDataRow(index, [
              new TableDataItem("Номер", (index + 1).toString()),
              new TableDataItem("Дата", item.orderDate ? item.orderDate : "-"),
              new TableDataItem("Тариф", item.subscriptionName),
              new TableDataItem("Статус", item.status),
              new TableDataItem("Метод оплаты", item.paymentMethod),
              new TableDataItem("Сумма", (item.amount / 100).toLocaleString()),
            ])
          )
      ),
    [historyPayment]
  );

  if (!isLoad) return <Loading color="dark" />;

  return (
    <>
      <div>
        <ul className={profileTarrifsCN("list")}>
          <li className={profileTarrifsCN("item")}>
            <div className={profileTarrifsCN("text-menu")}>Тариф:</div>
            <div className={profileTarrifsCN("text-default")}>
              {currentTariff.name}
            </div>
            <button
              type="button"
              className={profileTarrifsCN("button-link")}
              onClick={() => {
                setIsShowTariffs(true);
                sendMetrics("reachGoal", "changeTariffButtonClick");
              }}
            >
              Сменить тариф
            </button>
          </li>
          <li className={profileTarrifsCN("item")}>
            <div className={profileTarrifsCN("text-menu")}>
              Дата окончания тарифа:
            </div>
            <div
              className={profileTarrifsCN("text-default", {
                isRed: !isAccess,
                isGreen: isAccess,
              })}
            >
              {currentTariff.expiryDate}
            </div>
          </li>
          {/* <li className={profileTarrifsCN('item')}>
            <div className={profileTarrifsCN('text-menu')}>Запросов за сегодня:</div>
            <div className={profileTarrifsCN('text-default')}>0 из 3 доступных</div>
          </li>
          <li className={profileTarrifsCN('item')}>
            <div className={profileTarrifsCN('text-menu')}>Запросов через плагин:</div>
            <div className={profileTarrifsCN('text-default')}>0 из 3 доступных</div>
          </li>
          <li className={profileTarrifsCN('item')}>
            <div className={profileTarrifsCN('text-menu')}>
              Реквизиты организации:
              <br />
              (для счетов и актов)
            </div>
            <div className={profileTarrifsCN('text-default')}>—</div>
            <button
              type="button"
              className={profileTarrifsCN('button-link')}
              onClick={() => setIsShowDetail(true)}
            >
              Изменить
            </button>
          </li> */}
        </ul>
        {isShowDetail && (
          <ProfileInfoModalDetail
            backgroundClick={() => setIsShowDetail(false)}
            closeModal={() => setIsShowDetail(false)}
          />
        )}
      </div>
      <div className={profileTarrifsCN("title")}>История платежей</div>
      <div className={profileTarrifsCN("table-wrapper")}>
        <Table
          headers={[
            "Номер",
            "Дата",
            "Тариф",
            "Статус",
            "Метод оплаты",
            "Сумма",
          ]}
          data={tableBody}
        />
        {/* <Table className={profileTarrifsCN('table')}>
          <TableHead tableHeads={tableHead}></TableHead>
          <TableBody tableColumns={tableBody}></TableBody>
        </Table> */}
      </div>

      <Modal
        isShowDialog={isShowTariffs}
        setIsShowDialog={setIsShowTariffs}
        title={""}
      >
        <div className={profileTarrifsCN("tariff-modal")}>
          {tariffs && selectTariff && (
            <Selected
              active={2}
              items={tariffs
                .filter((t: Tariff) => t.basePrice !== 0)
                .map((t: Tariff) => new SelectItem(t.id, t.name))}
              OnChange={(id: number) => {
                setSelectTariff(tariffs.find((t: Tariff) => t.id == id));
              }}
            />
          )}
          {selectTariff && <p>{selectTariff.basePrice / 100} ₽</p>}
          <div
            onClick={() => onClickPayment()}
            className={profileTarrifsCN("green-btn")}
          >
            Оплатить
          </div>
          <div>
            {selectTariff &&
              selectTariff.description.map((item: string, i) => (
                <div key={item + i}>
                  <CheckMark />
                  {item}
                </div>
              ))}
            {/* <div>
              <div onClick={() => setIsAutoRenewal(!isAutoRenewal)}>
                {isAutoRenewal && <CheckMark />}
              </div>
              Автопродление
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

class SelectedItem {
  id: number;
  name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export default ProfileTariffs;
