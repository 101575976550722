import { bemCN } from "../../configs/bem-classname";
import ProfileInfo from "../../modules/profile-module/components/profile-info/profile-info";
import "./profile-info-page.scss"

const rootCN = bemCN("ProfileInfoPage");

export const ProfileInfoPage = () => {
  return (
    <div className={rootCN()}>
      <ProfileInfo/>
    </div>
  );
};