import "./check-group-component.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC, useEffect, useState } from "react";
import { ReactComponent as CheckMark } from "../../shared/images/svg/check-mark.svg";
import { ReactComponent as Folder } from "../../shared/images/svg/folder.svg";
import { count } from "console";

type CheckGroupProps = {
  id?: string;
  items: any[];
  onChange?(itemIndex: Map<any, any>): void;
  active?: number[];
};
export const CheckGroup: FC<CheckGroupProps> = ({
  id,
  items,
  active,
  onChange,
}) => {
  const isActiveItems = new Map();
  items.map((item) => isActiveItems.set(item.subjectName, item.select));
  const [activeItems, setAcriveItems] = useState(isActiveItems);
  const [activeAll, setAcriveAll] = useState(
    !Array.from(isActiveItems.values()).includes(false)
  );

  const checkGroupCN = bemCN("check-group");

  const onClickItem = (index: number, item: string) => {
    let active = new Map(activeItems.set(item, !activeItems.get(item)));
    setAcriveItems(active);
  };

  useEffect(() => {
    onChange && onChange(activeItems);
  }, [activeItems]);
  const onClickActiveAll = () => {
    setAcriveAll(!activeAll);
    activeItems.forEach(function (value, key, map) {
      setAcriveItems(new Map(activeItems.set(key, !activeAll)));
    });
  };

  return (
    <div className={checkGroupCN()}>
      <div key={-1} onClick={onClickActiveAll} className={checkGroupCN("item")}>
        <span className={checkGroupCN("activeAll", ["h4"])}>Выбрать все</span>
        <div className={activeAll ? "check-group__active" : ""}>
          {activeAll ? <CheckMark /> : null}
        </div>
      </div>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={(e) => onClickItem(index, item.subjectName)}
          className={checkGroupCN("item")}
        >
          <Folder />
          <span className="h4">{item.subjectName}</span>
          <div
            className={
              activeItems.get(item.subjectName) ? "check-group__active" : ""
            }
          >
            {activeItems.get(item.subjectName) ? <CheckMark /> : null}
          </div>
        </div>
      ))}
    </div>
  );
};
