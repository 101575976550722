import React, { FC, ReactNode, MouseEventHandler } from 'react';
import styles from './ModalForm.module.scss';
import './ModalForm.scss';
import { bemCN } from '../../configs/bem-classname';
import Modal from './Modal/Modal';

export interface ModalFormAbstract {
  onClickButton?: MouseEventHandler<HTMLButtonElement>;
  backgroundClick?: Function;
  closeModal?: MouseEventHandler<HTMLButtonElement>;
}

interface ModalFormProps extends ModalFormAbstract {
  className?: string;
  title: ReactNode;
  buttonText?: ReactNode;
  children: ReactNode;
}

const mainCN = bemCN("ModalForm");
const ModalForm: FC<ModalFormProps> = ({
  onClickButton,
  backgroundClick,
  closeModal,
  className,
  title,
  buttonText,
  children,
}) => (
  <Modal backgroundClick={backgroundClick}>
    <div className={mainCN()+" "+ className}>
      <div className={mainCN("top")}>
        <div className={mainCN('title')}>{title}</div>
        <button type="button" onClick={closeModal} className={mainCN('close')}>
          <svg
            width="1.87rem"
            height="1.87rem"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 24.0781L24 6.07813"
              stroke="var(--aside_text_background)"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              d="M23.918 24.1564L6.07573 5.99998"
              stroke="var(--aside_text_background)"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>
      <div className={mainCN('content')}>
        <div className={mainCN('children') }>{children}</div>
        <button
          type="button"
          className={mainCN('button') + ' button'}
          onClick={onClickButton}
        >
          {buttonText ?? title}
        </button>
      </div>
    </div>
  </Modal>
);

export default ModalForm;
