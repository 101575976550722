import { bemCN } from "../../../../configs/bem-classname";
import { InputText } from "../../../../ui/input-text/input-text";
import { SearchCheckFilter } from "../../../filter-module";
import { FilterAcceptance } from "../acceptances-component/acceptances-component";
import "./acceptance-filter-component.scss";
import { FC, useEffect, useState } from "react";

type AcceptanceFilterProps = {
  id?: string;
  filters?: FilterAcceptance;
  onChange?: (model: FilterAcceptance) => void;
};

export const AcceptanceFilter: FC<AcceptanceFilterProps> = ({
  id,
  filters,
  onChange,
}) => {
  const acceptanceFilterCN = bemCN("acceptanceFilter");
  const [model, setModel] = useState<FilterAcceptance | undefined>(filters);
  useEffect(() => {
    model && onChange && onChange(model);
  }, [model]);
  return (
    <div id={id} className={acceptanceFilterCN()}>
      <div className={acceptanceFilterCN("cost")}>
        <p className="h4">Себестоимость:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.totalCostPrice?.min && !model.totalCostPrice?.min.toString().includes('Infinity') 
                  ? model.totalCostPrice.min.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalCostPrice!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.totalCostPrice?.max && !model.totalCostPrice?.max.toString().includes('Infinity') 
                  ? model.totalCostPrice.max.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalCostPrice!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <p className="h4">Поставщик:</p>
        <SearchCheckFilter
          text={model?.provider?.join(",")}
          items={model && model.providerAll ? model.providerAll : []}
          onChange={(items) => {
            model!.provider = [];
            model?.providerAll?.map((m) => {
              let s = items.get(m.subjectName);
              m.status = s;
              if (s == true) {
                model!.provider?.push(m.subjectName);
              }
            });
            setModel({ ...model });
          }}
        />
      </div>
      <div className={acceptanceFilterCN("cost")}>
        <p className="h4">Сумма:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.totalPriceSelling?.min && !model.totalPriceSelling?.min.toString().includes('Infinity') 
                  ? model.totalPriceSelling.min.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalPriceSelling!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.totalPriceSelling?.max && !model.totalPriceSelling?.max.toString().includes('Infinity') 
                  ? model.totalPriceSelling.max.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalPriceSelling!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <p className="h4">Статус:</p>
        <SearchCheckFilter
          text={model?.acceptanceStatus?.join(",")}
          items={
            model && model.acceptanceStatusAll ? model.acceptanceStatusAll : []
          }
          onChange={(items) => {
            model!.acceptanceStatus = [];
            model?.acceptanceStatusAll?.map((m) => {
              let s = items.get(m.subjectName);
              m.status = s;
              if (s == true) {
                model!.acceptanceStatus?.push(m.subjectName);
              }
            });
            setModel({ ...model });
          }}
        />
      </div>
    </div>
  );
};
