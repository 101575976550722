import { Api } from "../core/api/Api";
import { Payment } from "../core/api/Payment";

export const API = new Api({
  baseURL: "https://api.awengroup.ru/",
  //baseURL: "https://localhost:5001/"
    withCredentials: true,
   
});
export const APIPay = new Payment({
  baseURL: "https://api.awengroup.ru/",
  //baseURL: "https://localhost:5001/"
    withCredentials: true,
   
});
