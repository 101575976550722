import "./sidebar-item-component.scss";
import { bemCN } from "../../../configs/bem-classname";
import { FC, ReactNode } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowTop } from "../../../shared/images/svg/arrow-top.svg";
import { ReactComponent as ArrowBottom } from "../../../shared/images/svg/arrow-bottom.svg";

type link = {
  name: string;
  path: string;
};

interface SideBarItemProps {
  id: number;
  title: string;
  icon: ReactNode;
  path?: string;
  links?: Array<link>;
  active: boolean;

  setActive(val: number | null): void;
}
const SideBarItem: FC<SideBarItemProps> = ({
  id,
  title,
  icon,
  path,
  links,
  active,
  setActive,
}) => {
  const sidebarItemCn = bemCN("sidebarItem");

  const navigate = useNavigate();
  return (
    <div
      className={sidebarItemCn()}
      onClick={() => {
        setActive(!active ? id : null);
      }}
    >
      <div>
        <div>{icon}</div>
        <div className={sidebarItemCn("title", ["h4"])} onClick={()=>{
          path&&navigate(path);
        }}>{title}</div>
        {!!links?.length && (
          <div className={sidebarItemCn("arrow")}>
            {active ? <ArrowBottom /> : <ArrowTop/>}
          </div>
        )}
      </div>

      { active && <div className={sidebarItemCn("subcategories")}>
        {links?.map((elem) => {
          return (
            <NavLink className="h5" key={elem.name} to={elem?.path}>
              {elem.name}
            </NavLink>
          );
        })}
      </div>}
    </div>
  );
};

export default SideBarItem;
