import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as Filter } from "../../../../shared/images/svg/filter.svg";
import { Button } from "../../../../ui/button/button";
import "./goods-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { FC, useEffect, useMemo, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../consts/api";
import { CategoriesFilter, SideFilterPanel } from "../../../filter-module";
import { GoodsFilter } from "../goods-filter-component/goods-filter-component";
import Loading from "../../../../components/loading-component/loading-component";
import { FilterGoodTableModel } from "../../../../core/api/data-contracts";

type GoodsProps = {
  id?: string;
};

export const Goods: FC<GoodsProps> = ({ id }) => {
  const goodsCN = bemCN("goods");
  const navigate = useNavigate();
  const [GoodsList, SetGoodsList] = useState<any[]>([]);
  const [CategoryList, SetCategoryList] = useState<any[]>([]);
  const [Headers, SetHeaders] = useState<any>();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [IsLoading, SetIsLoading] = useState(false);
  const [Error, SetError] = useState<Error>();
  const [finder, setFinder] = useState<string | undefined>();

  const [filters, setFilters] = useState<FilterGoodTableModel>({});
  const NavHome = () => {
    navigate("/home");
  };
  const onClickRow = (id: number) => {
    navigate("card/" + id);
  };
  useEffect(() => {
    API.goodGetAllItemsList({ finder })
      .then((res) => {
        if (!Headers) {
          SetHeaders(res.data.nameFields);
          let table = res.data.items;
          table && initFilters(table);
        }
        SetGoodsList([...res.data.items]);
        if (CategoryList?.length == 0) {
          let cat = [...res.data.categories];
          cat.map((e) => {
            e.subjects.map((s: any) => {
              s["select"] = false;
            });
          });
          SetCategoryList(cat);
        }
        SetIsLoading(true);
      })
      .catch((e) => {
        SetError(e);
        SetIsLoading(false);
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, [finder]);

  const initFilters = (table: any) => {
    filters.items = table;
    filters.balance = {};
    filters.priceCost = {};
    filters.pricePurchase = {};
    filters.priceSelling = {};
    filters.sales = {};
    filters.transit = {};
    filters.balance!.min = Math.min(...table.map((t: any) => t.balance));
    filters.balance!.max = Math.max(...table.map((t: any) => t.balance));

    filters.priceCost!.min = Math.min(...table.map((t: any) => t.priceCost));
    filters.priceCost!.max = Math.max(...table.map((t: any) => t.priceCost));

    filters.pricePurchase!.min = Math.min(
      ...table.map((t: any) => t.pricePurchase)
    );
    filters.pricePurchase!.max = Math.max(
      ...table.map((t: any) => t.pricePurchase)
    );

    filters.priceSelling!.min = Math.min(
      ...table.map((t: any) => t.priceSelling)
    );
    filters.priceSelling!.max = Math.max(
      ...table.map((t: any) => t.priceSelling)
    );

    filters.sales!.min = Math.min(...table.map((t: any) => t.sales));
    filters.sales!.max = Math.max(...table.map((t: any) => t.sales));

    filters.transit!.min = Math.min(...table.map((t: any) => t.transit));
    filters.transit!.max = Math.max(...table.map((t: any) => t.transit));
  };

  const onSaveFilters = (f: FilterGoodTableModel) => {
    GoodsList &&
      API.goodFilterTableCreate(f).then((res) => {
        setIsShowFilter(false);
        SetGoodsList([...res.data]);
      });
  };

  const onCancelFilters = () => {
    setIsShowFilter(false);
    API.goodGetAllItemsList({ finder })
      .then((res) => {
        if (!Headers) {
          SetHeaders(res.data.nameFields);
          let table = res.data.items;
          table && initFilters(table);
        }
        SetGoodsList([...res.data.items]);
        if (CategoryList?.length == 0) {
          let cat = [...res.data.categories];
          cat.map((e) => {
            e.subjects.map((s: any) => {
              s["select"] = false;
            });
          });
          SetCategoryList(cat);
        }
        SetIsLoading(true);
      })
      .catch((e) => {
        SetError(e);
        SetIsLoading(false);
      });
  };

  const onClickSort = (header: string, isDescending: boolean) => {
    GoodsList &&
      API.goodSortTableGoodCreate(GoodsList, {
        header,
        isDescending,
      }).then((res) => {
        SetGoodsList(res.data);
      });
  };

  const filterGoods = useMemo(() => {
    let subc: any[] = [];
    CategoryList.map((c) => {
      subc = subc.concat(c.subjects);
    });
    let selectSubc = subc.filter((f) => f.select);
    if (selectSubc.length <= 0) {
      selectSubc = subc;
    }
    selectSubc = selectSubc.map((m) => m.subjectId);
    return GoodsList.filter((f) => selectSubc.includes(f.category.subjectId));
  }, [CategoryList, GoodsList]);

  const onChange = (cat: any, items: Map<any, any>) => {
    CategoryList.map((c) => {
      if (c.parentId == cat.parentId) {
        c.subjects.map((s: any) => {
          if (items.get(s.subjectName)) {
            s.select = true;
          } else {
            s.select = false;
          }
        });
      }
    });
    SetCategoryList([...CategoryList]);
  };
  if (Error) {
    throw Error;
  }
  if (!IsLoading) {
    return <Loading color="dark" />;
  }
  return (
    <>
      <CategoriesFilter categories={CategoryList} onChange={onChange} />
      <div id={id} className={goodsCN()}>
        {isShowFilter ? (
          <SideFilterPanel
            isShow={isShowFilter}
            setIsShow={setIsShowFilter}
            onSave={() => onSaveFilters(filters)}
            onCancel={onCancelFilters}
          >
            <GoodsFilter filters={filters} onChange={setFilters} />
          </SideFilterPanel>
        ) : null}
        <div className={goodsCN("title", ["h2"])}>Список товаров</div>
        <div className={goodsCN("buttons")}>
          <Button
            text-image-left
            text="Добавить товар"
            icon={<PlusCircle />}
            onClick={() => navigate("card/new")}
          />
          {/* <ExportButton></ExportButton> */}
          <Button
            text-image-left
            text="Добавить склад"
            link="/directories/stocks/card/new"
            isTargetBlank
            icon={<PlusCircle />}
          />
          <Button
            text-image-left
            text="Фильтры"
            icon={<Filter />}
            onClick={() => setIsShowFilter(!isShowFilter)}
          />
          <InputText
            placeholder="Поиск по товарам"
            icon={<Search />}
            icon-left
            OnChange={setFinder}
          />
        </div>
        <Table
          isSortable
          onClickSort={onClickSort}
          dataGenerate={filterGoods}
          headersGenerate={Headers}
          onClickRow={onClickRow}
        />
      </div>
    </>
  );
};
