import { links } from './links'; 
export const profileLinks = [
    {
        title:"Мой профиль",
        path:links.profile + links.profileInfo
    },
    {
        title:"Тариф и оплата",
        path:links.profile + links.profileRate
    },
    {
        title:"Ключи",
        path:links.profile + links.profileKey
    },
    

]