import { ChangeEvent, FC, useState } from 'react';
import './edit-name-modal-form.scss';
import { bemCN } from '../../../../../../configs/bem-classname';
import { Modal } from '../../../../../../components/modal-component/modal-component';
import InputField from '../../../../../../ui/input-field/input-field';
import { Button } from '../../../../../../ui/button/button';
import { API } from '../../../../../../consts/api';

interface EditNameModalFormProps {
  isShowModal: boolean;
  setIsShowModal(isShowModal: boolean): void;
  user:any;
  setUser(user:any):void;
}
const editNameModalFormCN = bemCN('edit-name-modal-form');

const EditNameModalForm: FC<EditNameModalFormProps> = ({ isShowModal, setIsShowModal, user, setUser}) => {

  const [newName, setNewName] = useState("");
  const saveName = () =>{
    API.profileSetNameCreate({name:newName}).then(() =>{
      setUser({...user, name: newName});
      setIsShowModal(false);
    })
  }

  return (
    <Modal
      title={'Изменение имени пользователя'}
      isShowDialog={isShowModal}
      setIsShowDialog={setIsShowModal}
    >
      <div className={editNameModalFormCN()}>
        <InputField onChange={(v:React.ChangeEvent<HTMLInputElement>) => {setNewName(v.currentTarget.value)}}  className={editNameModalFormCN('input')} label="Введите имя"  />
        <Button blue text="Сохранить изменения" onClick={() => saveName()} />
      </div>
    </Modal>
  );
};

export default EditNameModalForm;
