/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AcceptanceStatus,
  ApiKey,
  ApiKeyModel,
  Bank,
  BankCity,
  Card,
  CardWithLocaleId,
  ChangePasswordDto,
  Contractor,
  ContractorTableModel,
  ContractorType,
  CurrentSubscriptionDto,
  DeliveryType,
  Expense,
  FilterGoodMovementTableModel,
  FilterGoodTableModel,
  FilterOrderTableModel,
  FilterPurchaseAcceptanceTableModel,
  FilterPurchaseTableModel,
  GoodCategory,
  GoodMeasurement,
  GoodMovement,
  GoodMovementTableModel,
  GoodTableModel,
  Image,
  LegalForm,
  MarginalityModel,
  MovementGoodModel,
  MovementStatus,
  OrderGoodModel,
  OrderTableModel,
  PaymentMethod,
  PhotoDto,
  Position,
  ProfileDto,
  Purchase,
  PurchaseAcceptance,
  PurchaseAcceptanceTableModel,
  PurchaseStatus,
  PurchaseTableModel,
  RecoverPasswordDto,
  Role,
  SiteUser,
  StatisticsItemModel,
  Store,
  StoreTableModel,
  StoreType,
  Task,
  TaskFolder,
  TaskModel,
  TokensDto,
  TransportCompany,
  UserLoginDto,
  UserRegistrationDto,
  UserTemp,
  Vat,
  WBGetSupplyInfo,
  WBGoodModel,
  WBOrderModel,
  WorkerTableModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AcceptanceStatus
   * @name AcceptanceStatusGetAllItemsList
   * @request GET:/api/AcceptanceStatus/GetAllItems
   */
  acceptanceStatusGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/AcceptanceStatus/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AcceptanceStatus
   * @name AcceptanceStatusDetail
   * @request GET:/api/AcceptanceStatus/{id}
   */
  acceptanceStatusDetail = (id: number, params: RequestParams = {}) =>
    this.request<AcceptanceStatus, any>({
      path: `/api/AcceptanceStatus/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AcceptanceStatus
   * @name AcceptanceStatusDelete
   * @request DELETE:/api/AcceptanceStatus/{id}
   */
  acceptanceStatusDelete = (id: number, params: RequestParams = {}) =>
    this.request<AcceptanceStatus, any>({
      path: `/api/AcceptanceStatus/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AcceptanceStatus
   * @name AcceptanceStatusAddCreate
   * @request POST:/api/AcceptanceStatus/Add
   */
  acceptanceStatusAddCreate = (data: AcceptanceStatus, params: RequestParams = {}) =>
    this.request<AcceptanceStatus, any>({
      path: `/api/AcceptanceStatus/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AcceptanceStatus
   * @name AcceptanceStatusUpdateUpdate
   * @request PUT:/api/AcceptanceStatus/Update
   */
  acceptanceStatusUpdateUpdate = (data: AcceptanceStatus, params: RequestParams = {}) =>
    this.request<AcceptanceStatus, any>({
      path: `/api/AcceptanceStatus/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthLogoutList
   * @request GET:/api/Auth/Logout
   */
  authLogoutList = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/Logout`,
      method: "GET",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthLoginCreate
   * @request POST:/api/Auth/Login
   */
  authLoginCreate = (data: UserLoginDto, params: RequestParams = {}) =>
    this.request<TokensDto, any>({
      path: `/api/Auth/Login`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthRegistrationCreate
   * @request POST:/api/Auth/Registration
   */
  authRegistrationCreate = (data: UserRegistrationDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/Registration`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthConfirmEmailList
   * @request GET:/api/Auth/ConfirmEmail
   */
  authConfirmEmailList = (
    query?: {
      email?: string;
      key?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/Auth/ConfirmEmail`,
      method: "GET",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthRecoverPasswordCreate
   * @request POST:/api/Auth/RecoverPassword
   */
  authRecoverPasswordCreate = (
    query?: {
      email?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/Auth/RecoverPassword`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthUpdatePasswordCreate
   * @request POST:/api/Auth/UpdatePassword
   */
  authUpdatePasswordCreate = (data: RecoverPasswordDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Auth/UpdatePassword`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetUserIdList
   * @request GET:/api/Auth/GetUserId
   */
  authGetUserIdList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Auth/GetUserId`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetUserList
   * @request GET:/api/Auth/GetUser
   */
  authGetUserList = (params: RequestParams = {}) =>
    this.request<SiteUser, any>({
      path: `/api/Auth/GetUser`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetUserNameList
   * @request GET:/api/Auth/GetUserName
   */
  authGetUserNameList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Auth/GetUserName`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetCompanyIdList
   * @request GET:/api/Auth/GetCompanyId
   */
  authGetCompanyIdList = (params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/Auth/GetCompanyId`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetCompanyNameList
   * @request GET:/api/Auth/GetCompanyName
   */
  authGetCompanyNameList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Auth/GetCompanyName`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthSetCompanyNameUpdate
   * @request PUT:/api/Auth/SetCompanyName
   */
  authSetCompanyNameUpdate = (
    query?: {
      name?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Auth/SetCompanyName`,
      method: "PUT",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetUsersCompanyList
   * @request GET:/api/Auth/GetUsersCompany
   */
  authGetUsersCompanyList = (params: RequestParams = {}) =>
    this.request<SiteUser[], any>({
      path: `/api/Auth/GetUsersCompany`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetApiKeyKindsList
   * @request GET:/api/Auth/GetApiKeyKinds
   */
  authGetApiKeyKindsList = (params: RequestParams = {}) =>
    this.request<ApiKey[], any>({
      path: `/api/Auth/GetApiKeyKinds`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthSetApiKeyUpdate
   * @request PUT:/api/Auth/SetApiKey
   */
  authSetApiKeyUpdate = (data: ApiKeyModel, params: RequestParams = {}) =>
    this.request<boolean, any>({
      path: `/api/Auth/SetApiKey`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name AuthGetApiKeysUpdate
   * @request PUT:/api/Auth/GetApiKeys
   */
  authGetApiKeysUpdate = (params: RequestParams = {}) =>
    this.request<ApiKeyModel[], any>({
      path: `/api/Auth/GetApiKeys`,
      method: "PUT",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Bank
   * @name BankGetAllItemsList
   * @request GET:/api/Bank/GetAllItems
   */
  bankGetAllItemsList = (
    query?: {
      finder?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Bank[], any>({
      path: `/api/Bank/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Bank
   * @name BankDetail
   * @request GET:/api/Bank/{id}
   */
  bankDetail = (id: number, params: RequestParams = {}) =>
    this.request<Bank, any>({
      path: `/api/Bank/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Bank
   * @name BankDelete
   * @request DELETE:/api/Bank/{id}
   */
  bankDelete = (id: number, params: RequestParams = {}) =>
    this.request<Bank, any>({
      path: `/api/Bank/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Bank
   * @name BankAddCreate
   * @request POST:/api/Bank/Add
   */
  bankAddCreate = (data: Bank, params: RequestParams = {}) =>
    this.request<Bank, any>({
      path: `/api/Bank/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Bank
   * @name BankUpdateUpdate
   * @request PUT:/api/Bank/Update
   */
  bankUpdateUpdate = (data: Bank, params: RequestParams = {}) =>
    this.request<Bank, any>({
      path: `/api/Bank/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Bank
   * @name BankAddAllBanksCreate
   * @request POST:/api/Bank/AddAllBanks
   */
  bankAddAllBanksCreate = (params: RequestParams = {}) =>
    this.request<BankCity[], any>({
      path: `/api/Bank/AddAllBanks`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankCity
   * @name BankCityGetAllItemsList
   * @request GET:/api/BankCity/GetAllItems
   */
  bankCityGetAllItemsList = (
    query?: {
      finder?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BankCity[], any>({
      path: `/api/BankCity/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankCity
   * @name BankCityDetail
   * @request GET:/api/BankCity/{id}
   */
  bankCityDetail = (id: number, params: RequestParams = {}) =>
    this.request<BankCity, any>({
      path: `/api/BankCity/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankCity
   * @name BankCityDelete
   * @request DELETE:/api/BankCity/{id}
   */
  bankCityDelete = (id: number, params: RequestParams = {}) =>
    this.request<BankCity, any>({
      path: `/api/BankCity/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankCity
   * @name BankCityAddCreate
   * @request POST:/api/BankCity/Add
   */
  bankCityAddCreate = (data: BankCity, params: RequestParams = {}) =>
    this.request<BankCity, any>({
      path: `/api/BankCity/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankCity
   * @name BankCityUpdateUpdate
   * @request PUT:/api/BankCity/Update
   */
  bankCityUpdateUpdate = (data: BankCity, params: RequestParams = {}) =>
    this.request<BankCity, any>({
      path: `/api/BankCity/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankCity
   * @name BankCityAddAllCitiesCreate
   * @request POST:/api/BankCity/AddAllCities
   */
  bankCityAddAllCitiesCreate = (params: RequestParams = {}) =>
    this.request<BankCity[], any>({
      path: `/api/BankCity/AddAllCities`,
      method: "POST",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contractor
   * @name ContractorGetAllItemsList
   * @request GET:/api/Contractor/GetAllItems
   */
  contractorGetAllItemsList = (
    query?: {
      finder?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/Contractor/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contractor
   * @name ContractorDetail
   * @request GET:/api/Contractor/{id}
   */
  contractorDetail = (id: number, params: RequestParams = {}) =>
    this.request<ContractorTableModel, any>({
      path: `/api/Contractor/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contractor
   * @name ContractorDelete
   * @request DELETE:/api/Contractor/{id}
   */
  contractorDelete = (id: number, params: RequestParams = {}) =>
    this.request<Contractor, any>({
      path: `/api/Contractor/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contractor
   * @name ContractorAddCreate
   * @request POST:/api/Contractor/Add
   */
  contractorAddCreate = (data: ContractorTableModel, params: RequestParams = {}) =>
    this.request<ContractorTableModel, any>({
      path: `/api/Contractor/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contractor
   * @name ContractorUpdateUpdate
   * @request PUT:/api/Contractor/Update
   */
  contractorUpdateUpdate = (data: ContractorTableModel, params: RequestParams = {}) =>
    this.request<ContractorTableModel, any>({
      path: `/api/Contractor/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Contractor
   * @name ContractorSortTableContractorCreate
   * @request POST:/api/Contractor/SortTableContractor
   */
  contractorSortTableContractorCreate = (
    data: ContractorTableModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<ContractorTableModel[], any>({
      path: `/api/Contractor/SortTableContractor`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContractorType
   * @name ContractorTypeGetAllItemsList
   * @request GET:/api/ContractorType/GetAllItems
   */
  contractorTypeGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/ContractorType/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContractorType
   * @name ContractorTypeDetail
   * @request GET:/api/ContractorType/{id}
   */
  contractorTypeDetail = (id: number, params: RequestParams = {}) =>
    this.request<ContractorType, any>({
      path: `/api/ContractorType/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContractorType
   * @name ContractorTypeDelete
   * @request DELETE:/api/ContractorType/{id}
   */
  contractorTypeDelete = (id: number, params: RequestParams = {}) =>
    this.request<ContractorType, any>({
      path: `/api/ContractorType/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContractorType
   * @name ContractorTypeAddCreate
   * @request POST:/api/ContractorType/Add
   */
  contractorTypeAddCreate = (data: ContractorType, params: RequestParams = {}) =>
    this.request<ContractorType, any>({
      path: `/api/ContractorType/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContractorType
   * @name ContractorTypeUpdateUpdate
   * @request PUT:/api/ContractorType/Update
   */
  contractorTypeUpdateUpdate = (data: ContractorType, params: RequestParams = {}) =>
    this.request<ContractorType, any>({
      path: `/api/ContractorType/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags DeliveryType
   * @name DeliveryTypeGetAllItemsList
   * @request GET:/api/DeliveryType/GetAllItems
   */
  deliveryTypeGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/DeliveryType/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags DeliveryType
   * @name DeliveryTypeDetail
   * @request GET:/api/DeliveryType/{id}
   */
  deliveryTypeDetail = (id: number, params: RequestParams = {}) =>
    this.request<DeliveryType, any>({
      path: `/api/DeliveryType/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags DeliveryType
   * @name DeliveryTypeDelete
   * @request DELETE:/api/DeliveryType/{id}
   */
  deliveryTypeDelete = (id: number, params: RequestParams = {}) =>
    this.request<DeliveryType, any>({
      path: `/api/DeliveryType/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags DeliveryType
   * @name DeliveryTypeAddCreate
   * @request POST:/api/DeliveryType/Add
   */
  deliveryTypeAddCreate = (data: DeliveryType, params: RequestParams = {}) =>
    this.request<DeliveryType, any>({
      path: `/api/DeliveryType/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags DeliveryType
   * @name DeliveryTypeUpdateUpdate
   * @request PUT:/api/DeliveryType/Update
   */
  deliveryTypeUpdateUpdate = (data: DeliveryType, params: RequestParams = {}) =>
    this.request<DeliveryType, any>({
      path: `/api/DeliveryType/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expense
   * @name ExpenseGetAllItemsList
   * @request GET:/api/Expense/GetAllItems
   */
  expenseGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/Expense/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expense
   * @name ExpenseDetail
   * @request GET:/api/Expense/{id}
   */
  expenseDetail = (id: number, params: RequestParams = {}) =>
    this.request<Expense, any>({
      path: `/api/Expense/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expense
   * @name ExpenseDelete
   * @request DELETE:/api/Expense/{id}
   */
  expenseDelete = (id: number, params: RequestParams = {}) =>
    this.request<Expense, any>({
      path: `/api/Expense/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expense
   * @name ExpenseAddCreate
   * @request POST:/api/Expense/Add
   */
  expenseAddCreate = (data: Expense, params: RequestParams = {}) =>
    this.request<Expense, any>({
      path: `/api/Expense/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expense
   * @name ExpenseUpdateUpdate
   * @request PUT:/api/Expense/Update
   */
  expenseUpdateUpdate = (data: Expense, params: RequestParams = {}) =>
    this.request<Expense, any>({
      path: `/api/Expense/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetAllItemsList
   * @request GET:/api/Good/GetAllItems
   */
  goodGetAllItemsList = (
    query?: {
      finder?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/Good/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodDetail
   * @request GET:/api/Good/{id}
   */
  goodDetail = (id: number, params: RequestParams = {}) =>
    this.request<CardWithLocaleId[], any>({
      path: `/api/Good/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetGoodPurchaseList
   * @request GET:/api/Good/GetGoodPurchase
   */
  goodGetGoodPurchaseList = (
    query?: {
      /** @format int32 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/Good/GetGoodPurchase`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetRemaindersOnStoresList
   * @request GET:/api/Good/GetRemaindersOnStores
   */
  goodGetRemaindersOnStoresList = (
    query?: {
      /** @format int32 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/Good/GetRemaindersOnStores`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetPurchaseAcceptanceGoodForChartList
   * @request GET:/api/Good/GetPurchaseAcceptanceGoodForChart
   */
  goodGetPurchaseAcceptanceGoodForChartList = (
    query?: {
      /** @format int32 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<StatisticsItemModel[], any>({
      path: `/api/Good/GetPurchaseAcceptanceGoodForChart`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetSalesGoodForChartList
   * @request GET:/api/Good/GetSalesGoodForChart
   */
  goodGetSalesGoodForChartList = (
    query?: {
      /** @format int32 */
      id?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<StatisticsItemModel[], any>({
      path: `/api/Good/GetSalesGoodForChart`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodAddCreate
   * @request POST:/api/Good/Add
   */
  goodAddCreate = (data: Card, params: RequestParams = {}) =>
    this.request<Card, any>({
      path: `/api/Good/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodAddVariantCreate
   * @request POST:/api/Good/AddVariant
   */
  goodAddVariantCreate = (data: Card, params: RequestParams = {}) =>
    this.request<Card, any>({
      path: `/api/Good/AddVariant`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodAddMediaCreate
   * @request POST:/api/Good/AddMedia
   */
  goodAddMediaCreate = (
    data: {
      /** @format binary */
      formFile?: File;
    },
    query?: {
      articleWB?: string;
      /** @format int32 */
      position?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Good/AddMedia`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodDeleteMediaCreate
   * @request POST:/api/Good/DeleteMedia
   */
  goodDeleteMediaCreate = (
    data: Card,
    query?: {
      /** @format int32 */
      position?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Good/DeleteMedia`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodUpdateUpdate
   * @request PUT:/api/Good/Update
   */
  goodUpdateUpdate = (data: Card, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Good/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodDeleteDelete
   * @request DELETE:/api/Good/Delete
   */
  goodDeleteDelete = (data: Card[], params: RequestParams = {}) =>
    this.request<Card[], any>({
      path: `/api/Good/Delete`,
      method: "DELETE",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbGoodsList
   * @request GET:/api/Good/GetWBGoods
   */
  goodGetWbGoodsList = (params: RequestParams = {}) =>
    this.request<WBGoodModel, any>({
      path: `/api/Good/GetWBGoods`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbCategoriesList
   * @request GET:/api/Good/GetWBCategories
   */
  goodGetWbCategoriesList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Good/GetWBCategories`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbSubjectsList
   * @request GET:/api/Good/GetWBSubjects
   */
  goodGetWbSubjectsList = (
    query?: {
      /** @format int32 */
      parentId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/Good/GetWBSubjects`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbCategoriesAndSubjectsByNameList
   * @request GET:/api/Good/GetWBCategoriesAndSubjectsByName
   */
  goodGetWbCategoriesAndSubjectsByNameList = (
    query?: {
      name?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/Good/GetWBCategoriesAndSubjectsByName`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbCharacteristicsBySubjectIdList
   * @request GET:/api/Good/GetWBCharacteristicsBySubjectId
   */
  goodGetWbCharacteristicsBySubjectIdList = (
    query?: {
      /** @format int32 */
      subjectId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/Good/GetWBCharacteristicsBySubjectId`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbColorsList
   * @request GET:/api/Good/GetWBColors
   */
  goodGetWbColorsList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Good/GetWBColors`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbKindsList
   * @request GET:/api/Good/GetWBKinds
   */
  goodGetWbKindsList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Good/GetWBKinds`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbCountriesList
   * @request GET:/api/Good/GetWBCountries
   */
  goodGetWbCountriesList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Good/GetWBCountries`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbSeasonsList
   * @request GET:/api/Good/GetWBSeasons
   */
  goodGetWbSeasonsList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Good/GetWBSeasons`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbTnvedList
   * @request GET:/api/Good/GetWBTnved
   */
  goodGetWbTnvedList = (
    query?: {
      /** @format int32 */
      subjectId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/Good/GetWBTnved`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodGetWbVatList
   * @request GET:/api/Good/GetWBVat
   */
  goodGetWbVatList = (params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/Good/GetWBVat`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodUpdateCategoryUpdate
   * @request PUT:/api/Good/UpdateCategory
   */
  goodUpdateCategoryUpdate = (params: RequestParams = {}) =>
    this.request<boolean, any>({
      path: `/api/Good/UpdateCategory`,
      method: "PUT",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodSortTableGoodCreate
   * @request POST:/api/Good/SortTableGood
   */
  goodSortTableGoodCreate = (
    data: GoodTableModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<GoodTableModel[], any>({
      path: `/api/Good/SortTableGood`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Good
   * @name GoodFilterTableCreate
   * @request POST:/api/Good/FilterTable
   */
  goodFilterTableCreate = (data: FilterGoodTableModel, params: RequestParams = {}) =>
    this.request<GoodTableModel[], any>({
      path: `/api/Good/FilterTable`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodCategory
   * @name GoodCategoryGetAllItemsList
   * @request GET:/api/GoodCategory/GetAllItems
   */
  goodCategoryGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/GoodCategory/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodCategory
   * @name GoodCategoryDetail
   * @request GET:/api/GoodCategory/{id}
   */
  goodCategoryDetail = (id: number, params: RequestParams = {}) =>
    this.request<GoodCategory, any>({
      path: `/api/GoodCategory/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodCategory
   * @name GoodCategoryDelete
   * @request DELETE:/api/GoodCategory/{id}
   */
  goodCategoryDelete = (id: number, params: RequestParams = {}) =>
    this.request<GoodCategory, any>({
      path: `/api/GoodCategory/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodCategory
   * @name GoodCategoryAddCreate
   * @request POST:/api/GoodCategory/Add
   */
  goodCategoryAddCreate = (data: GoodCategory, params: RequestParams = {}) =>
    this.request<GoodCategory, any>({
      path: `/api/GoodCategory/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodCategory
   * @name GoodCategoryUpdateUpdate
   * @request PUT:/api/GoodCategory/Update
   */
  goodCategoryUpdateUpdate = (data: GoodCategory, params: RequestParams = {}) =>
    this.request<GoodCategory, any>({
      path: `/api/GoodCategory/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMeasurement
   * @name GoodMeasurementGetAllItemsList
   * @request GET:/api/GoodMeasurement/GetAllItems
   */
  goodMeasurementGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/GoodMeasurement/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMeasurement
   * @name GoodMeasurementDetail
   * @request GET:/api/GoodMeasurement/{id}
   */
  goodMeasurementDetail = (id: number, params: RequestParams = {}) =>
    this.request<GoodMeasurement, any>({
      path: `/api/GoodMeasurement/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMeasurement
   * @name GoodMeasurementDelete
   * @request DELETE:/api/GoodMeasurement/{id}
   */
  goodMeasurementDelete = (id: number, params: RequestParams = {}) =>
    this.request<GoodMeasurement, any>({
      path: `/api/GoodMeasurement/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMeasurement
   * @name GoodMeasurementAddCreate
   * @request POST:/api/GoodMeasurement/Add
   */
  goodMeasurementAddCreate = (data: GoodMeasurement, params: RequestParams = {}) =>
    this.request<GoodMeasurement, any>({
      path: `/api/GoodMeasurement/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMeasurement
   * @name GoodMeasurementUpdateUpdate
   * @request PUT:/api/GoodMeasurement/Update
   */
  goodMeasurementUpdateUpdate = (data: GoodMeasurement, params: RequestParams = {}) =>
    this.request<GoodMeasurement, any>({
      path: `/api/GoodMeasurement/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementGetNameGoodFieldsList
   * @request GET:/api/GoodMovement/GetNameGoodFields
   */
  goodMovementGetNameGoodFieldsList = (params: RequestParams = {}) =>
    this.request<Record<string, string>, any>({
      path: `/api/GoodMovement/GetNameGoodFields`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementGetAllItemsList
   * @request GET:/api/GoodMovement/GetAllItems
   */
  goodMovementGetAllItemsList = (
    query?: {
      finder?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/GoodMovement/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementDetail
   * @request GET:/api/GoodMovement/{id}
   */
  goodMovementDetail = (id: number, params: RequestParams = {}) =>
    this.request<GoodMovementTableModel, any>({
      path: `/api/GoodMovement/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementDelete
   * @request DELETE:/api/GoodMovement/{id}
   */
  goodMovementDelete = (id: number, params: RequestParams = {}) =>
    this.request<GoodMovement, any>({
      path: `/api/GoodMovement/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementAddCreate
   * @request POST:/api/GoodMovement/Add
   */
  goodMovementAddCreate = (data: GoodMovementTableModel, params: RequestParams = {}) =>
    this.request<GoodMovementTableModel, any>({
      path: `/api/GoodMovement/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementUpdateUpdate
   * @request PUT:/api/GoodMovement/Update
   */
  goodMovementUpdateUpdate = (data: GoodMovementTableModel, params: RequestParams = {}) =>
    this.request<GoodMovementTableModel, any>({
      path: `/api/GoodMovement/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementCloseUpdate
   * @request PUT:/api/GoodMovement/Close
   */
  goodMovementCloseUpdate = (data: GoodMovementTableModel, params: RequestParams = {}) =>
    this.request<GoodMovementTableModel, any>({
      path: `/api/GoodMovement/Close`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementGetGoodsbyStoreIdList
   * @request GET:/api/GoodMovement/GetGoodsbyStoreId
   */
  goodMovementGetGoodsbyStoreIdList = (
    query?: {
      /** @format int32 */
      storeId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<MovementGoodModel[], any>({
      path: `/api/GoodMovement/GetGoodsbyStoreId`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementUpdateCostPriceCreate
   * @request POST:/api/GoodMovement/UpdateCostPrice
   */
  goodMovementUpdateCostPriceCreate = (data: GoodMovementTableModel, params: RequestParams = {}) =>
    this.request<GoodMovementTableModel, any>({
      path: `/api/GoodMovement/UpdateCostPrice`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementSortTableMovementCreate
   * @request POST:/api/GoodMovement/SortTableMovement
   */
  goodMovementSortTableMovementCreate = (
    data: GoodMovementTableModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<GoodMovementTableModel[], any>({
      path: `/api/GoodMovement/SortTableMovement`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags GoodMovement
   * @name GoodMovementFilterTableCreate
   * @request POST:/api/GoodMovement/FilterTable
   */
  goodMovementFilterTableCreate = (data: FilterGoodMovementTableModel, params: RequestParams = {}) =>
    this.request<GoodMovementTableModel[], any>({
      path: `/api/GoodMovement/FilterTable`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Image
   * @name ImageGetAllItemsList
   * @request GET:/api/Image/GetAllItems
   */
  imageGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/Image/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Image
   * @name ImageDetail
   * @request GET:/api/Image/{id}
   */
  imageDetail = (id: number, params: RequestParams = {}) =>
    this.request<Image, any>({
      path: `/api/Image/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Image
   * @name ImageDelete
   * @request DELETE:/api/Image/{id}
   */
  imageDelete = (id: number, params: RequestParams = {}) =>
    this.request<Image, any>({
      path: `/api/Image/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Image
   * @name ImageAddCreate
   * @request POST:/api/Image/Add
   */
  imageAddCreate = (data: Image, params: RequestParams = {}) =>
    this.request<Image, any>({
      path: `/api/Image/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Image
   * @name ImageUpdateUpdate
   * @request PUT:/api/Image/Update
   */
  imageUpdateUpdate = (data: Image, params: RequestParams = {}) =>
    this.request<Image, any>({
      path: `/api/Image/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LegalForm
   * @name LegalFormGetAllItemsList
   * @request GET:/api/LegalForm/GetAllItems
   */
  legalFormGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/LegalForm/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LegalForm
   * @name LegalFormDetail
   * @request GET:/api/LegalForm/{id}
   */
  legalFormDetail = (id: number, params: RequestParams = {}) =>
    this.request<LegalForm, any>({
      path: `/api/LegalForm/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LegalForm
   * @name LegalFormDelete
   * @request DELETE:/api/LegalForm/{id}
   */
  legalFormDelete = (id: number, params: RequestParams = {}) =>
    this.request<LegalForm, any>({
      path: `/api/LegalForm/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LegalForm
   * @name LegalFormAddCreate
   * @request POST:/api/LegalForm/Add
   */
  legalFormAddCreate = (data: LegalForm, params: RequestParams = {}) =>
    this.request<LegalForm, any>({
      path: `/api/LegalForm/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LegalForm
   * @name LegalFormUpdateUpdate
   * @request PUT:/api/LegalForm/Update
   */
  legalFormUpdateUpdate = (data: LegalForm, params: RequestParams = {}) =>
    this.request<LegalForm, any>({
      path: `/api/LegalForm/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MovementStatus
   * @name MovementStatusGetAllItemsList
   * @request GET:/api/MovementStatus/GetAllItems
   */
  movementStatusGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/MovementStatus/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MovementStatus
   * @name MovementStatusDetail
   * @request GET:/api/MovementStatus/{id}
   */
  movementStatusDetail = (id: number, params: RequestParams = {}) =>
    this.request<MovementStatus, any>({
      path: `/api/MovementStatus/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MovementStatus
   * @name MovementStatusDelete
   * @request DELETE:/api/MovementStatus/{id}
   */
  movementStatusDelete = (id: number, params: RequestParams = {}) =>
    this.request<MovementStatus, any>({
      path: `/api/MovementStatus/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MovementStatus
   * @name MovementStatusAddCreate
   * @request POST:/api/MovementStatus/Add
   */
  movementStatusAddCreate = (data: MovementStatus, params: RequestParams = {}) =>
    this.request<MovementStatus, any>({
      path: `/api/MovementStatus/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MovementStatus
   * @name MovementStatusUpdateUpdate
   * @request PUT:/api/MovementStatus/Update
   */
  movementStatusUpdateUpdate = (data: MovementStatus, params: RequestParams = {}) =>
    this.request<MovementStatus, any>({
      path: `/api/MovementStatus/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetNameGoodFieldsList
   * @request GET:/api/Order/GetNameGoodFields
   */
  orderGetNameGoodFieldsList = (params: RequestParams = {}) =>
    this.request<Record<string, string>, any>({
      path: `/api/Order/GetNameGoodFields`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetCardFieldsList
   * @request GET:/api/Order/GetCardFields
   */
  orderGetCardFieldsList = (params: RequestParams = {}) =>
    this.request<Record<string, string>, any>({
      path: `/api/Order/GetCardFields`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetAllItemsList
   * @request GET:/api/Order/GetAllItems
   */
  orderGetAllItemsList = (
    query?: {
      finder?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/Order/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderDetail
   * @request GET:/api/Order/{id}
   */
  orderDetail = (id: number, params: RequestParams = {}) =>
    this.request<OrderTableModel, any>({
      path: `/api/Order/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderUpdateUpdate
   * @request PUT:/api/Order/Update
   */
  orderUpdateUpdate = (data: OrderTableModel, params: RequestParams = {}) =>
    this.request<OrderTableModel, any>({
      path: `/api/Order/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderCloseSupplyUpdate
   * @request PUT:/api/Order/CloseSupply
   */
  orderCloseSupplyUpdate = (
    data: OrderTableModel,
    query?: {
      supplyId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderTableModel, any>({
      path: `/api/Order/CloseSupply`,
      method: "PUT",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetWbOrdersList
   * @request GET:/api/Order/GetWBOrders
   */
  orderGetWbOrdersList = (params: RequestParams = {}) =>
    this.request<WBOrderModel, any>({
      path: `/api/Order/GetWBOrders`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetWbSuppliesList
   * @request GET:/api/Order/GetWBSupplies
   */
  orderGetWbSuppliesList = (params: RequestParams = {}) =>
    this.request<WBGetSupplyInfo[], any>({
      path: `/api/Order/GetWBSupplies`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetWbSupplyList
   * @request GET:/api/Order/GetWBSupply
   */
  orderGetWbSupplyList = (
    query?: {
      supplyId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<WBGetSupplyInfo, any>({
      path: `/api/Order/GetWBSupply`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetWbOrdersInSupplyList
   * @request GET:/api/Order/GetWBOrdersInSupply
   */
  orderGetWbOrdersInSupplyList = (
    query?: {
      supplyId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<WBOrderModel, any>({
      path: `/api/Order/GetWBOrdersInSupply`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderCreateSupplyCreate
   * @request POST:/api/Order/CreateSupply
   */
  orderCreateSupplyCreate = (
    query?: {
      name?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/Order/CreateSupply`,
      method: "POST",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderDeleteSupplyDelete
   * @request DELETE:/api/Order/DeleteSupply
   */
  orderDeleteSupplyDelete = (
    query?: {
      supplyId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Order/DeleteSupply`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderGetSupplyQrCodeList
   * @request GET:/api/Order/GetSupplyQRCode
   */
  orderGetSupplyQrCodeList = (
    query?: {
      supplyId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/Order/GetSupplyQRCode`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderAddBoxToSupplyCreate
   * @request POST:/api/Order/AddBoxToSupply
   */
  orderAddBoxToSupplyCreate = (
    query?: {
      supplyId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/Order/AddBoxToSupply`,
      method: "POST",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderDeleteBoxFromSupplyDelete
   * @request DELETE:/api/Order/DeleteBoxFromSupply
   */
  orderDeleteBoxFromSupplyDelete = (
    data: string[],
    query?: {
      supplyId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Order/DeleteBoxFromSupply`,
      method: "DELETE",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderAddOrdersToBoxPartialUpdate
   * @request PATCH:/api/Order/AddOrdersToBox
   */
  orderAddOrdersToBoxPartialUpdate = (
    data: number[],
    query?: {
      supplyId?: string;
      trbxId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Order/AddOrdersToBox`,
      method: "PATCH",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderDeleteOrderInBoxDelete
   * @request DELETE:/api/Order/DeleteOrderInBox
   */
  orderDeleteOrderInBoxDelete = (
    query?: {
      supplyId?: string;
      trbxId?: string;
      /** @format int64 */
      orderId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Order/DeleteOrderInBox`,
      method: "DELETE",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderCancelOrderPartialUpdate
   * @request PATCH:/api/Order/CancelOrder
   */
  orderCancelOrderPartialUpdate = (
    query?: {
      /** @format int64 */
      orderId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<boolean, any>({
      path: `/api/Order/CancelOrder`,
      method: "PATCH",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderCancelOrderGoodModelPartialUpdate
   * @request PATCH:/api/Order/CancelOrderGoodModel
   */
  orderCancelOrderGoodModelPartialUpdate = (data: OrderGoodModel, params: RequestParams = {}) =>
    this.request<boolean, any>({
      path: `/api/Order/CancelOrderGoodModel`,
      method: "PATCH",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderSortTableOrderCreate
   * @request POST:/api/Order/SortTableOrder
   */
  orderSortTableOrderCreate = (
    data: OrderTableModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderTableModel[], any>({
      path: `/api/Order/SortTableOrder`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Order
   * @name OrderFilterTableCreate
   * @request POST:/api/Order/FilterTable
   */
  orderFilterTableCreate = (data: FilterOrderTableModel, params: RequestParams = {}) =>
    this.request<OrderTableModel[], any>({
      path: `/api/Order/FilterTable`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PaymentMethod
   * @name PaymentMethodGetAllItemsList
   * @request GET:/api/PaymentMethod/GetAllItems
   */
  paymentMethodGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/PaymentMethod/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PaymentMethod
   * @name PaymentMethodDetail
   * @request GET:/api/PaymentMethod/{id}
   */
  paymentMethodDetail = (id: number, params: RequestParams = {}) =>
    this.request<PaymentMethod, any>({
      path: `/api/PaymentMethod/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PaymentMethod
   * @name PaymentMethodDelete
   * @request DELETE:/api/PaymentMethod/{id}
   */
  paymentMethodDelete = (id: number, params: RequestParams = {}) =>
    this.request<PaymentMethod, any>({
      path: `/api/PaymentMethod/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PaymentMethod
   * @name PaymentMethodAddCreate
   * @request POST:/api/PaymentMethod/Add
   */
  paymentMethodAddCreate = (data: PaymentMethod, params: RequestParams = {}) =>
    this.request<PaymentMethod, any>({
      path: `/api/PaymentMethod/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PaymentMethod
   * @name PaymentMethodUpdateUpdate
   * @request PUT:/api/PaymentMethod/Update
   */
  paymentMethodUpdateUpdate = (data: PaymentMethod, params: RequestParams = {}) =>
    this.request<PaymentMethod, any>({
      path: `/api/PaymentMethod/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Position
   * @name PositionGetAllItemsList
   * @request GET:/api/Position/GetAllItems
   */
  positionGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/Position/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Position
   * @name PositionDetail
   * @request GET:/api/Position/{id}
   */
  positionDetail = (id: number, params: RequestParams = {}) =>
    this.request<Position, any>({
      path: `/api/Position/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Position
   * @name PositionDelete
   * @request DELETE:/api/Position/{id}
   */
  positionDelete = (id: number, params: RequestParams = {}) =>
    this.request<Position, any>({
      path: `/api/Position/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Position
   * @name PositionAddCreate
   * @request POST:/api/Position/Add
   */
  positionAddCreate = (data: Position, params: RequestParams = {}) =>
    this.request<Position, any>({
      path: `/api/Position/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Position
   * @name PositionUpdateUpdate
   * @request PUT:/api/Position/Update
   */
  positionUpdateUpdate = (data: Position, params: RequestParams = {}) =>
    this.request<Position, any>({
      path: `/api/Position/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileSetNameCreate
   * @request POST:/api/Profile/SetName
   */
  profileSetNameCreate = (
    query?: {
      name?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/Profile/SetName`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileSetPhoneNumberCreate
   * @request POST:/api/Profile/SetPhoneNumber
   */
  profileSetPhoneNumberCreate = (
    query?: {
      phoneNumber?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/Profile/SetPhoneNumber`,
      method: "POST",
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileSetPhotoCreate
   * @request POST:/api/Profile/SetPhoto
   */
  profileSetPhotoCreate = (
    data: {
      /** @format binary */
      file?: File;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/Profile/SetPhoto`,
      method: "POST",
      body: data,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileSetPhoto2Create
   * @request POST:/api/Profile/SetPhoto2
   */
  profileSetPhoto2Create = (data: PhotoDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Profile/SetPhoto2`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileGetPhotoList
   * @request GET:/api/Profile/GetPhoto
   */
  profileGetPhotoList = (params: RequestParams = {}) =>
    this.request<PhotoDto, any>({
      path: `/api/Profile/GetPhoto`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileGetPhoto2List
   * @request GET:/api/Profile/GetPhoto2
   */
  profileGetPhoto2List = (params: RequestParams = {}) =>
    this.request<PhotoDto, any>({
      path: `/api/Profile/GetPhoto2`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileGetProfileList
   * @request GET:/api/Profile/GetProfile
   */
  profileGetProfileList = (params: RequestParams = {}) =>
    this.request<ProfileDto, any>({
      path: `/api/Profile/GetProfile`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileGetCurrentSubscriptionList
   * @request GET:/api/Profile/GetCurrentSubscription
   */
  profileGetCurrentSubscriptionList = (params: RequestParams = {}) =>
    this.request<CurrentSubscriptionDto, any>({
      path: `/api/Profile/GetCurrentSubscription`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Profile
   * @name ProfileChangePasswordDtoCreate
   * @request POST:/api/Profile/ChangePasswordDto
   */
  profileChangePasswordDtoCreate = (data: ChangePasswordDto, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/Profile/ChangePasswordDto`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseGetNameGoodFieldsList
   * @request GET:/api/Purchase/GetNameGoodFields
   */
  purchaseGetNameGoodFieldsList = (params: RequestParams = {}) =>
    this.request<Record<string, string>, any>({
      path: `/api/Purchase/GetNameGoodFields`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseGetAllItemsList
   * @request GET:/api/Purchase/GetAllItems
   */
  purchaseGetAllItemsList = (
    query?: {
      finder?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/Purchase/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseGetClosedAndNotFinishedPurchaseList
   * @request GET:/api/Purchase/GetClosedAndNotFinishedPurchase
   */
  purchaseGetClosedAndNotFinishedPurchaseList = (params: RequestParams = {}) =>
    this.request<PurchaseTableModel[], any>({
      path: `/api/Purchase/GetClosedAndNotFinishedPurchase`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseDetail
   * @request GET:/api/Purchase/{id}
   */
  purchaseDetail = (id: number, params: RequestParams = {}) =>
    this.request<PurchaseTableModel, any>({
      path: `/api/Purchase/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseDelete
   * @request DELETE:/api/Purchase/{id}
   */
  purchaseDelete = (id: number, params: RequestParams = {}) =>
    this.request<Purchase, any>({
      path: `/api/Purchase/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseAddCreate
   * @request POST:/api/Purchase/Add
   */
  purchaseAddCreate = (data: PurchaseTableModel, params: RequestParams = {}) =>
    this.request<PurchaseTableModel, any>({
      path: `/api/Purchase/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseUpdateUpdate
   * @request PUT:/api/Purchase/Update
   */
  purchaseUpdateUpdate = (data: PurchaseTableModel, params: RequestParams = {}) =>
    this.request<PurchaseTableModel, any>({
      path: `/api/Purchase/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseCloseUpdate
   * @request PUT:/api/Purchase/Close
   */
  purchaseCloseUpdate = (data: PurchaseTableModel, params: RequestParams = {}) =>
    this.request<PurchaseTableModel, any>({
      path: `/api/Purchase/Close`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseUpdateCostPriceCreate
   * @request POST:/api/Purchase/UpdateCostPrice
   */
  purchaseUpdateCostPriceCreate = (data: PurchaseTableModel, params: RequestParams = {}) =>
    this.request<PurchaseTableModel, any>({
      path: `/api/Purchase/UpdateCostPrice`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseSortTablePurchaseCreate
   * @request POST:/api/Purchase/SortTablePurchase
   */
  purchaseSortTablePurchaseCreate = (
    data: PurchaseTableModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<PurchaseTableModel[], any>({
      path: `/api/Purchase/SortTablePurchase`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Purchase
   * @name PurchaseFilterTableCreate
   * @request POST:/api/Purchase/FilterTable
   */
  purchaseFilterTableCreate = (data: FilterPurchaseTableModel, params: RequestParams = {}) =>
    this.request<PurchaseTableModel[], any>({
      path: `/api/Purchase/FilterTable`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceGetNameGoodFieldsList
   * @request GET:/api/PurchaseAcceptance/GetNameGoodFields
   */
  purchaseAcceptanceGetNameGoodFieldsList = (params: RequestParams = {}) =>
    this.request<Record<string, string>, any>({
      path: `/api/PurchaseAcceptance/GetNameGoodFields`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceGetAllItemsList
   * @request GET:/api/PurchaseAcceptance/GetAllItems
   */
  purchaseAcceptanceGetAllItemsList = (
    query?: {
      finder?: string;
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/PurchaseAcceptance/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceDetail
   * @request GET:/api/PurchaseAcceptance/{id}
   */
  purchaseAcceptanceDetail = (id: number, params: RequestParams = {}) =>
    this.request<PurchaseAcceptanceTableModel, any>({
      path: `/api/PurchaseAcceptance/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceDelete
   * @request DELETE:/api/PurchaseAcceptance/{id}
   */
  purchaseAcceptanceDelete = (id: number, params: RequestParams = {}) =>
    this.request<PurchaseAcceptance, any>({
      path: `/api/PurchaseAcceptance/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceAddCreate
   * @request POST:/api/PurchaseAcceptance/Add
   */
  purchaseAcceptanceAddCreate = (data: PurchaseAcceptanceTableModel, params: RequestParams = {}) =>
    this.request<PurchaseAcceptanceTableModel, any>({
      path: `/api/PurchaseAcceptance/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceUpdateUpdate
   * @request PUT:/api/PurchaseAcceptance/Update
   */
  purchaseAcceptanceUpdateUpdate = (data: PurchaseAcceptanceTableModel, params: RequestParams = {}) =>
    this.request<PurchaseAcceptanceTableModel, any>({
      path: `/api/PurchaseAcceptance/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceCloseUpdate
   * @request PUT:/api/PurchaseAcceptance/Close
   */
  purchaseAcceptanceCloseUpdate = (data: PurchaseAcceptanceTableModel, params: RequestParams = {}) =>
    this.request<PurchaseAcceptanceTableModel, any>({
      path: `/api/PurchaseAcceptance/Close`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceUpdateCostPriceCreate
   * @request POST:/api/PurchaseAcceptance/UpdateCostPrice
   */
  purchaseAcceptanceUpdateCostPriceCreate = (data: PurchaseAcceptanceTableModel, params: RequestParams = {}) =>
    this.request<PurchaseAcceptanceTableModel, any>({
      path: `/api/PurchaseAcceptance/UpdateCostPrice`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceSortTableAcceptanceCreate
   * @request POST:/api/PurchaseAcceptance/SortTableAcceptance
   */
  purchaseAcceptanceSortTableAcceptanceCreate = (
    data: PurchaseAcceptanceTableModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<PurchaseAcceptanceTableModel[], any>({
      path: `/api/PurchaseAcceptance/SortTableAcceptance`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseAcceptance
   * @name PurchaseAcceptanceFilterTableCreate
   * @request POST:/api/PurchaseAcceptance/FilterTable
   */
  purchaseAcceptanceFilterTableCreate = (data: FilterPurchaseAcceptanceTableModel, params: RequestParams = {}) =>
    this.request<PurchaseAcceptanceTableModel[], any>({
      path: `/api/PurchaseAcceptance/FilterTable`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseStatus
   * @name PurchaseStatusGetAllItemsList
   * @request GET:/api/PurchaseStatus/GetAllItems
   */
  purchaseStatusGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/PurchaseStatus/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseStatus
   * @name PurchaseStatusDetail
   * @request GET:/api/PurchaseStatus/{id}
   */
  purchaseStatusDetail = (id: number, params: RequestParams = {}) =>
    this.request<PurchaseStatus, any>({
      path: `/api/PurchaseStatus/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseStatus
   * @name PurchaseStatusDelete
   * @request DELETE:/api/PurchaseStatus/{id}
   */
  purchaseStatusDelete = (id: number, params: RequestParams = {}) =>
    this.request<PurchaseStatus, any>({
      path: `/api/PurchaseStatus/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseStatus
   * @name PurchaseStatusAddCreate
   * @request POST:/api/PurchaseStatus/Add
   */
  purchaseStatusAddCreate = (data: PurchaseStatus, params: RequestParams = {}) =>
    this.request<PurchaseStatus, any>({
      path: `/api/PurchaseStatus/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PurchaseStatus
   * @name PurchaseStatusUpdateUpdate
   * @request PUT:/api/PurchaseStatus/Update
   */
  purchaseStatusUpdateUpdate = (data: PurchaseStatus, params: RequestParams = {}) =>
    this.request<PurchaseStatus, any>({
      path: `/api/PurchaseStatus/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name RoleGetAllItemsList
   * @request GET:/api/Role/GetAllItems
   */
  roleGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/Role/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name RoleDetail
   * @request GET:/api/Role/{id}
   */
  roleDetail = (id: number, params: RequestParams = {}) =>
    this.request<Role, any>({
      path: `/api/Role/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name RoleDelete
   * @request DELETE:/api/Role/{id}
   */
  roleDelete = (id: number, params: RequestParams = {}) =>
    this.request<Role, any>({
      path: `/api/Role/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name RoleAddCreate
   * @request POST:/api/Role/Add
   */
  roleAddCreate = (data: Role, params: RequestParams = {}) =>
    this.request<Role, any>({
      path: `/api/Role/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Role
   * @name RoleUpdateUpdate
   * @request PUT:/api/Role/Update
   */
  roleUpdateUpdate = (data: Role, params: RequestParams = {}) =>
    this.request<Role, any>({
      path: `/api/Role/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsGetPurchaseStatisticsList
   * @request GET:/api/Statistics/GetPurchaseStatistics
   */
  statisticsGetPurchaseStatisticsList = (
    query?: {
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<StatisticsItemModel[], any>({
      path: `/api/Statistics/GetPurchaseStatistics`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsGetOrderStatisticsList
   * @request GET:/api/Statistics/GetOrderStatistics
   */
  statisticsGetOrderStatisticsList = (
    query?: {
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<StatisticsItemModel[], any>({
      path: `/api/Statistics/GetOrderStatistics`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsGetRevenueStatisticsList
   * @request GET:/api/Statistics/GetRevenueStatistics
   */
  statisticsGetRevenueStatisticsList = (
    query?: {
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<StatisticsItemModel[], any>({
      path: `/api/Statistics/GetRevenueStatistics`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsGetOrbrStatisticsList
   * @request GET:/api/Statistics/GetORBRStatistics
   */
  statisticsGetOrbrStatisticsList = (
    query?: {
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<StatisticsItemModel[], any>({
      path: `/api/Statistics/GetORBRStatistics`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsGetNameFieldsMarginalityList
   * @request GET:/api/Statistics/GetNameFieldsMarginality
   */
  statisticsGetNameFieldsMarginalityList = (params: RequestParams = {}) =>
    this.request<Record<string, string>, any>({
      path: `/api/Statistics/GetNameFieldsMarginality`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsGetTableMarginalityStatisticsList
   * @request GET:/api/Statistics/GetTableMarginalityStatistics
   */
  statisticsGetTableMarginalityStatisticsList = (
    query?: {
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<MarginalityModel[], any>({
      path: `/api/Statistics/GetTableMarginalityStatistics`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsSortTableMarginalityStatisticsCreate
   * @request POST:/api/Statistics/SortTableMarginalityStatistics
   */
  statisticsSortTableMarginalityStatisticsCreate = (
    data: MarginalityModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<MarginalityModel[], any>({
      path: `/api/Statistics/SortTableMarginalityStatistics`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Statistics
   * @name StatisticsGetProfitAndRevenueStatisticsList
   * @request GET:/api/Statistics/GetProfitAndRevenueStatistics
   */
  statisticsGetProfitAndRevenueStatisticsList = (
    query?: {
      /** @format date-time */
      start?: string;
      /** @format date-time */
      end?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<StatisticsItemModel[], any>({
      path: `/api/Statistics/GetProfitAndRevenueStatistics`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name StoreGetAllItemsList
   * @request GET:/api/Store/GetAllItems
   */
  storeGetAllItemsList = (
    query?: {
      finder?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<Record<string, any>, any>({
      path: `/api/Store/GetAllItems`,
      method: "GET",
      query: query,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name StoreDetail
   * @request GET:/api/Store/{id}
   */
  storeDetail = (id: number, params: RequestParams = {}) =>
    this.request<StoreTableModel, any>({
      path: `/api/Store/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name StoreDelete
   * @request DELETE:/api/Store/{id}
   */
  storeDelete = (id: number, params: RequestParams = {}) =>
    this.request<Store, any>({
      path: `/api/Store/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name StoreAddCreate
   * @request POST:/api/Store/Add
   */
  storeAddCreate = (data: StoreTableModel, params: RequestParams = {}) =>
    this.request<StoreTableModel, any>({
      path: `/api/Store/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name StoreUpdateUpdate
   * @request PUT:/api/Store/Update
   */
  storeUpdateUpdate = (data: StoreTableModel, params: RequestParams = {}) =>
    this.request<StoreTableModel, any>({
      path: `/api/Store/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Store
   * @name StoreSortTableStoreCreate
   * @request POST:/api/Store/SortTableStore
   */
  storeSortTableStoreCreate = (
    data: StoreTableModel[],
    query?: {
      header?: string;
      isDescending?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<StoreTableModel[], any>({
      path: `/api/Store/SortTableStore`,
      method: "POST",
      query: query,
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StoreType
   * @name StoreTypeGetAllItemsList
   * @request GET:/api/StoreType/GetAllItems
   */
  storeTypeGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/StoreType/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StoreType
   * @name StoreTypeDetail
   * @request GET:/api/StoreType/{id}
   */
  storeTypeDetail = (id: number, params: RequestParams = {}) =>
    this.request<StoreType, any>({
      path: `/api/StoreType/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StoreType
   * @name StoreTypeDelete
   * @request DELETE:/api/StoreType/{id}
   */
  storeTypeDelete = (id: number, params: RequestParams = {}) =>
    this.request<StoreType, any>({
      path: `/api/StoreType/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StoreType
   * @name StoreTypeAddCreate
   * @request POST:/api/StoreType/Add
   */
  storeTypeAddCreate = (data: StoreType, params: RequestParams = {}) =>
    this.request<StoreType, any>({
      path: `/api/StoreType/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StoreType
   * @name StoreTypeUpdateUpdate
   * @request PUT:/api/StoreType/Update
   */
  storeTypeUpdateUpdate = (data: StoreType, params: RequestParams = {}) =>
    this.request<StoreType, any>({
      path: `/api/StoreType/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name TaskGetAllItemsList
   * @request GET:/api/Task/GetAllItems
   */
  taskGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/Task/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name TaskDetail
   * @request GET:/api/Task/{id}
   */
  taskDetail = (id: number, params: RequestParams = {}) =>
    this.request<TaskModel, any>({
      path: `/api/Task/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name TaskDelete
   * @request DELETE:/api/Task/{id}
   */
  taskDelete = (id: number, params: RequestParams = {}) =>
    this.request<Task, any>({
      path: `/api/Task/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name TaskAddCreate
   * @request POST:/api/Task/Add
   */
  taskAddCreate = (data: TaskModel, params: RequestParams = {}) =>
    this.request<Task, any>({
      path: `/api/Task/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Task
   * @name TaskUpdateUpdate
   * @request PUT:/api/Task/Update
   */
  taskUpdateUpdate = (data: TaskModel, params: RequestParams = {}) =>
    this.request<Task, any>({
      path: `/api/Task/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFolder
   * @name TaskFolderGetAllItemsList
   * @request GET:/api/TaskFolder/GetAllItems
   */
  taskFolderGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/TaskFolder/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFolder
   * @name TaskFolderDetail
   * @request GET:/api/TaskFolder/{id}
   */
  taskFolderDetail = (id: number, params: RequestParams = {}) =>
    this.request<TaskFolder, any>({
      path: `/api/TaskFolder/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFolder
   * @name TaskFolderDelete
   * @request DELETE:/api/TaskFolder/{id}
   */
  taskFolderDelete = (id: number, params: RequestParams = {}) =>
    this.request<TaskFolder, any>({
      path: `/api/TaskFolder/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFolder
   * @name TaskFolderAddCreate
   * @request POST:/api/TaskFolder/Add
   */
  taskFolderAddCreate = (data: TaskFolder, params: RequestParams = {}) =>
    this.request<TaskFolder, any>({
      path: `/api/TaskFolder/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaskFolder
   * @name TaskFolderUpdateUpdate
   * @request PUT:/api/TaskFolder/Update
   */
  taskFolderUpdateUpdate = (data: TaskFolder, params: RequestParams = {}) =>
    this.request<TaskFolder, any>({
      path: `/api/TaskFolder/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TransportCompany
   * @name TransportCompanyGetAllItemsList
   * @request GET:/api/TransportCompany/GetAllItems
   */
  transportCompanyGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/TransportCompany/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TransportCompany
   * @name TransportCompanyDetail
   * @request GET:/api/TransportCompany/{id}
   */
  transportCompanyDetail = (id: number, params: RequestParams = {}) =>
    this.request<TransportCompany, any>({
      path: `/api/TransportCompany/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TransportCompany
   * @name TransportCompanyDelete
   * @request DELETE:/api/TransportCompany/{id}
   */
  transportCompanyDelete = (id: number, params: RequestParams = {}) =>
    this.request<TransportCompany, any>({
      path: `/api/TransportCompany/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TransportCompany
   * @name TransportCompanyAddCreate
   * @request POST:/api/TransportCompany/Add
   */
  transportCompanyAddCreate = (data: TransportCompany, params: RequestParams = {}) =>
    this.request<TransportCompany, any>({
      path: `/api/TransportCompany/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TransportCompany
   * @name TransportCompanyUpdateUpdate
   * @request PUT:/api/TransportCompany/Update
   */
  transportCompanyUpdateUpdate = (data: TransportCompany, params: RequestParams = {}) =>
    this.request<TransportCompany, any>({
      path: `/api/TransportCompany/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Vat
   * @name VatGetAllItemsList
   * @request GET:/api/Vat/GetAllItems
   */
  vatGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/Vat/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Vat
   * @name GetApi
   * @request GET:/api/Vat/{id}
   */
  getApi = (id: number, params: RequestParams = {}) =>
    this.request<Vat, any>({
      path: `/api/Vat/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Vat
   * @name DeleteApi
   * @request DELETE:/api/Vat/{id}
   */
  deleteApi = (id: number, params: RequestParams = {}) =>
    this.request<Vat, any>({
      path: `/api/Vat/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Vat
   * @name VatAddCreate
   * @request POST:/api/Vat/Add
   */
  vatAddCreate = (data: Vat, params: RequestParams = {}) =>
    this.request<Vat, any>({
      path: `/api/Vat/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Vat
   * @name VatUpdateUpdate
   * @request PUT:/api/Vat/Update
   */
  vatUpdateUpdate = (data: Vat, params: RequestParams = {}) =>
    this.request<Vat, any>({
      path: `/api/Vat/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Worker
   * @name WorkerGetAllItemsList
   * @request GET:/api/Worker/GetAllItems
   */
  workerGetAllItemsList = (params: RequestParams = {}) =>
    this.request<Record<string, any>, any>({
      path: `/api/Worker/GetAllItems`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Worker
   * @name WorkerDetail
   * @request GET:/api/Worker/{id}
   */
  workerDetail = (id: number, params: RequestParams = {}) =>
    this.request<WorkerTableModel, any>({
      path: `/api/Worker/${id}`,
      method: "GET",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Worker
   * @name WorkerDelete
   * @request DELETE:/api/Worker/{id}
   */
  workerDelete = (id: number, params: RequestParams = {}) =>
    this.request<UserTemp, any>({
      path: `/api/Worker/${id}`,
      method: "DELETE",
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Worker
   * @name WorkerAddCreate
   * @request POST:/api/Worker/Add
   */
  workerAddCreate = (data: WorkerTableModel, params: RequestParams = {}) =>
    this.request<WorkerTableModel, any>({
      path: `/api/Worker/Add`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Worker
   * @name WorkerUpdateUpdate
   * @request PUT:/api/Worker/Update
   */
  workerUpdateUpdate = (data: WorkerTableModel, params: RequestParams = {}) =>
    this.request<WorkerTableModel, any>({
      path: `/api/Worker/Update`,
      method: "PUT",
      body: data,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
