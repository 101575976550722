import { FC, useEffect, useState } from "react";
import "./profile-info.scss";
import EditNameModalForm from "./profile-modal-forms/edit-name-modal-form/edit-name-modal-form";
import EditPhoneModalForm from "./profile-modal-forms/edit-phone-modal-form/edit-phone-modal-form";
import EditPasswordModalForm from "./profile-modal-forms/edit-password-modal-form/edit-password-modal-form";
import { ReactComponent as ProfileDefualt } from "../../../../shared/images/svg/profile-link.svg";
import { bemCN } from "../../../../configs/bem-classname";
import { useDispatch, useSelector } from "react-redux";
import SmallSpinner from "../../../../ui/small-spinner/small-spinner";
import { API } from "../../../../consts/api";
import { useNavigate } from "react-router-dom";

interface ProfileInfoProps {}

const profileInfoCN = bemCN("profileInfo");

const ProfileInfo: FC<ProfileInfoProps> = () => {
  const [isShowName, setIsShowName] = useState(false);
  const [isShowPhone, setIsShowPhone] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  // const dispatch = useDispatch();
  // const photo = useSelector((state: any) => state.profilePhotoReducer)?.profilePhoto;

  // const loadImage = () => {
  //   const input: HTMLInputElement = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.accept = 'image/jpg, image/jpeg';

  //   input.onchange = () => {
  //     let file;
  //     if (input !== null && input.files != null) {
  //       file = input.files[0];
  //       if (file.size / (1024 * 1024) < 10) {
  //         getBase64(file);
  //       } else {
  //         alert('Размер файла не должен превышать 10 МБ.');
  //       }
  //     }
  //   };

  //   input.click();
  // };

  // const deletePhoto = () => {
  //   profileService.setPhoto('').then(() => {
  //     typedDispatch(setPhoto('null'));
  //   });
  // };

  // const getBase64 = (file: Blob) => {
  //   var reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     let r = reader.result?.slice(23);
  //     profileService.setPhoto(r).then(() => {
  //       typedDispatch(setPhoto(r as string));
  //     });
  //   };
  //   reader.onerror = function (error) {
  //     return '';
  //   };
  //   return '';
  // };

  const [user, setUser] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const NavHome = () => {
    navigate("/home");
  };
  useEffect(() => {
    API.authGetUserList()
      .then((res) => {
        setUser(res.data);
        setIsLoading(true);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, [1]);

  return (
    <div className={profileInfoCN()}>
      <div>
        <div className={profileInfoCN("avatar")}>
          <ProfileDefualt />
        </div>
        {/* <p
          onClick={() => {
            // deletePhoto();
          }}
        >
          Удалить фото
        </p> */}
      </div>

      <div>
        <ul className={profileInfoCN("list")}>
          <li className={profileInfoCN("item")}>
            <div className={profileInfoCN("text-menu")}>Имя</div>
            <div
              onClick={() => setIsShowName(true)}
              className={profileInfoCN("text-default")}
            >
              {isLoading ? (
                user?.name !== "" ? (
                  user?.name
                ) : (
                  "Заполнить данные"
                )
              ) : (
                <SmallSpinner />
              )}
            </div>
          </li>
          <li className={profileInfoCN("item")}>
            <div className={profileInfoCN("text-menu")}>E-mail</div>
            <div
              id="email-info"
              data-title="Для изменения адреса электронной почты обратитесь в службу поддержки"
              className={profileInfoCN("text-default")}
            >
              {isLoading ? user?.email : <SmallSpinner />}
            </div>
          </li>
          <li className={profileInfoCN("item")}>
            <div className={profileInfoCN("text-menu")}>Пароль</div>
            <div className={profileInfoCN("text-default")}>
              <button
                type="button"
                className={profileInfoCN("button-link")}
                onClick={() => setIsShowPassword(true)}
              >
                Изменить пароль
              </button>
            </div>
          </li>
          <li className={profileInfoCN("item")}>
            <div className={profileInfoCN("text-menu")}>Телефон</div>
            <div
              onClick={() => setIsShowPhone(true)}
              className={profileInfoCN("text-default")}
            >
              {isLoading ? user?.phoneNumber : <SmallSpinner />}
            </div>
          </li>
        </ul>
      </div>

      {isShowName ? (
        <EditNameModalForm
          isShowModal={isShowName}
          setIsShowModal={setIsShowName}
          user={user}
          setUser={setUser}
        />
      ) : null}
      {isShowPhone ? (
        <EditPhoneModalForm
          isShowModal={isShowPhone}
          setIsShowModal={setIsShowPhone}
          user={user}
          setUser={setUser}
        />
      ) : null}
      {isShowPassword ? (
        <EditPasswordModalForm
          isShowModal={isShowPassword}
          setIsShowModal={setIsShowPassword}
        />
      ) : null}
    </div>
  );
};

export default ProfileInfo;
