import { bemCN } from "../../configs/bem-classname";
import { StockCard } from "../../modules/stock-module";
import "./stock-card-page.scss"
const stockCN = bemCN("StockCardPage");

export const StockCardPage = () => {
  return (
    <div className={stockCN()}>
      <StockCard/>
    </div>
  );
};