import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import { ReactComponent as Filter } from "../../../../shared/images/svg/filter.svg";
import "./movings-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { FC, useEffect, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { useNavigate } from "react-router-dom";
import { SideFilterPanel } from "../../../filter-module";
import { MovingFilter } from "../moving-filter-component/moving-filter-component";
import { API } from "../../../../consts/api";
import Loading from "../../../../components/loading-component/loading-component";
import { SearchInTable } from "../../../../shared/helpers/search-in-table";
import { FilterGoodMovementTableModel } from "../../../../core/api/data-contracts";

type MovingsProps = {
  id?: string;
};
export interface FilterMoving extends FilterGoodMovementTableModel {
  contractorAndStoreSourceAll?: any[];
  contractorAndStoreDestitationAll?: any[];
}
export const Movings: FC<MovingsProps> = ({ id }) => {
  const movingsCN = bemCN("movings");
  const navigate = useNavigate();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [movements, setMovements] = useState<any[]>([]);
  const [finder, setFinder] = useState<string | undefined>();
  const [headers, setHeaders] = useState<any>();
  const [headersGoods, setHeadersGood] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [date, setDate] = useState<any[]>();
  const [filters, setFilters] = useState<FilterMoving>({});

  const onClickRow = (id: number) => {
    navigate("card/" + id, {
      replace: true,
      state: {
        movement: movements.find((e) => e.id === id),
        headersGoods: headersGoods,
      },
    });
  };

  const onClickAdd = () => {
    navigate("card/new", {
      replace: true,
      state: { headersGoods: headersGoods },
    });
  };

  useEffect(() => {
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.goodMovementGetAllItemsList({ finder, start, end })
      .then((res) => {
        if (!headers) {
          setHeaders(res.data.nameFields);
          let table = res.data.items;
          table && initFilters(table);
        }
        setMovements([...res.data.items]);
        if (!headersGoods) setHeadersGood(res.data.nameGoodFields);
        setIsLoading(true);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
      });
  }, [finder, date]);
  const initFilters = (table: any) => {
    filters.items = table;
    filters.priceCost = {};
    filters.priceCost!.min = Math.min(...table.map((t: any) => t.priceCost));
    filters.priceCost!.max = Math.max(...table.map((t: any) => t.priceCost));

    filters.contractorAndStoreDestitation = Array.from(
      new Set(table.map((t: any) => t.contractorAndStoreDestitation))
    );
    filters.contractorAndStoreDestitationAll = Array.from(
      new Set(table.map((t: any) => t.contractorAndStoreDestitation))
    ).map((e) => {
      return { subjectName: e, select: true };
    });

    filters.contractorAndStoreSource = Array.from(
      new Set(table.map((t: any) => t.contractorAndStoreSource))
    );
    filters.contractorAndStoreSourceAll = Array.from(
      new Set(table.map((t: any) => t.contractorAndStoreSource))
    ).map((e) => {
      return { subjectName: e, select: true };
    });
  };

  const onSaveFilters = (f: FilterMoving) => {
    movements &&
      API.goodMovementFilterTableCreate(f).then((res) => {
        setIsShowFilter(false);
        setMovements([...res.data]);
      });
  };
  const NavHome = () => {
    navigate("/home");
  };
  const onCancelFilters = () => {
    setIsShowFilter(false);
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.goodMovementGetAllItemsList({ finder, start, end })
      .then((res) => {
        let table = res.data.items;
        table && initFilters(table);
        if (!headers) {
          setHeaders(res.data.nameFields);
        }
        setMovements([...res.data.items]);
        if (!headersGoods) {
          setHeadersGood(res.data.nameGoodFields);
        }
        setIsLoading(true);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
        if (e.request.status == 401) {
          NavHome();
        }
      });
  };

  const onClickSort = (header: string, isDescending: boolean) => {
    movements &&
      API.goodMovementSortTableMovementCreate(movements, {
        header,
        isDescending,
      }).then((res) => {
        setMovements(res.data);
      });
  };

  if (error) {
    throw Error;
  }
  if (!isLoading) {
    return <Loading color="dark" />;
  }

  return (
    <div id={id} className={movingsCN()}>
      {isShowFilter ? (
        <SideFilterPanel
          isShow={isShowFilter}
          setIsShow={setIsShowFilter}
          onSave={() => onSaveFilters(filters)}
          onCancel={onCancelFilters}
        >
          <MovingFilter filters={filters} onChange={setFilters} />
        </SideFilterPanel>
      ) : null}
      <div className={movingsCN("topPanel")}>
        {/* <RadioCalendar /> */}
        <Calendar ChangeDate={setDate} />
      </div>
      <div className={movingsCN("title", ["h1"])}>Перемещения</div>
      <div className={movingsCN("buttons")}>
        <Button
          text-image-left
          text="Фильтры"
          onClick={() => setIsShowFilter(!isShowFilter)}
          icon={<Filter />}
        />
        <Button
          text-image-left
          text="Добавить перемещение"
          icon={<PlusCircle />}
          onClick={onClickAdd}
        />
        {/* <ExportButton></ExportButton> */}
        <InputText
          placeholder="Поиск по перемещению"
          OnChange={setFinder}
          icon={<Search />}
          icon-left
        />
      </div>
      <Table
        isSortable
        onClickSort={onClickSort}
        onClickRow={onClickRow}
        dataGenerate={movements}
        headersGenerate={headers}
      />
    </div>
  );
};
