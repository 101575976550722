import "./marketplace-barcode-button-component.scss";
import { FC, useEffect, useState } from "react";
import { ReactComponent as Barcode } from "../../../../shared/images/svg/barcode.svg";
import { ReactComponent as Arrow } from "../../../../shared/images/svg/arrow-bottom-triangle.svg";

import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import MarketplaceBarcodePopup from "../marketplace-barcode-popup-component/marketplace-barcode-popup-component";

type MarketplaceBarcodeButtonProps = {
  id?: string;
  onCheck?(index: number): void;
};

const MarketplaceBarcodeButton: FC<MarketplaceBarcodeButtonProps> = ({
  id,
  onCheck,
}) => {
  const radioCalendarCardCN = bemCN("marketplace-barcode-button");
  const items = ["Стикер Ozon", "Стикер WB"];

  const [currentMarketplace, setCurrentMarketplace] = useState(-1);
  const [isShow, setIsShow] = useState(false);

  const onClick = () => {
    setIsShow(!isShow);
  };
  return (
    <div className={radioCalendarCardCN("", ["h4"])}>
      <Button
        text-double-image
        onClick={onClick}
        text={items[currentMarketplace]}
        icon2={<Arrow />}
        icon={
          <div
            onClick={() => {
              onCheck && onCheck(currentMarketplace);
            }}
          >
            <Barcode />
          </div>
        }
      />
      <MarketplaceBarcodePopup
        isShow={isShow}
        setIsShow={setIsShow}
        setCurrentMarketplace={setCurrentMarketplace}
        items={items}
        active={-1}
      />
    </div>
  );
};

export default MarketplaceBarcodeButton;
