import { FC, useEffect, useState } from 'react';
import { Modal } from '../../../../../../components/modal-component/modal-component';
import InputFieldPassword from '../../../../../../ui/InputFieldPassword/InputFieldPassword';
import { passwordHasError } from '../../../../../../shared/helpers/validation';
import { Link } from 'react-router-dom';
import { links } from '../../../../../../consts/links';
import { Button } from '../../../../../../ui/button/button';
import { bemCN } from '../../../../../../configs/bem-classname';
import "./edit-password-modal-form.scss"
import { API } from '../../../../../../consts/api';

interface EditPasswordModalFormProps {
  isShowModal: boolean;
  setIsShowModal(isShowModal: boolean): void;
}
const editPasswordModalFormCN = bemCN('edit-password-modal-form');

const EditPasswordModalForm: FC<EditPasswordModalFormProps> = ({ isShowModal, setIsShowModal }) => {
  const [oldPassword, setOldPassword1] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [isMatch, setIsMatch] = useState(false);
  const [status, setStatus] = useState('Пароль успешно изменен.');
  const [isResult, setisResult] = useState(false);


  useEffect(() => {
    if (newPassword1 === newPassword2) setIsMatch(true);
    else setIsMatch(false);
  }, [newPassword1, newPassword2]);

  const savePassword = () => {
    API.profileChangePasswordDtoCreate({oldPassword, newPassword:newPassword1}).then((res: any) => {

      if (res.request.status === 422) setStatus('Неверно введен старый пароль');
      setisResult(true);
    });
  };

  return (
    <Modal title={'Изменение пароля'} isShowDialog={isShowModal} setIsShowDialog={setIsShowModal}>
      <div className={editPasswordModalFormCN()}>
        <InputFieldPassword
          onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
            setOldPassword1(v.currentTarget.value);
          }}
          className={editPasswordModalFormCN('input')}
          label="Старый пароль"
        />
        <InputFieldPassword
          validators={[passwordHasError]}
          onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
            setNewPassword1(v.currentTarget.value);
          }}
          className={editPasswordModalFormCN('input')}
          label="Новый пароль"
        />
        <InputFieldPassword
          onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
            setNewPassword2(v.currentTarget.value);
          }}
          className={editPasswordModalFormCN('input')}
          label="Подтвердите новый пароль"
        />
        {!isMatch ? (
          <p className={editPasswordModalFormCN('error')}>Пароли не совпадают.</p>
        ) : (
          <p className={editPasswordModalFormCN('success')}>Пароли совпадают.</p>
        )}
        <Link className={editPasswordModalFormCN('link')}  to={links.passwordRecovery}>
          Забыли пароль?
        </Link>
        {isResult && <p>{status}</p>}

        <Button blue text="Сохранить изменения" onClick={() => savePassword()} />
      </div>
    </Modal>
  );
};

export default EditPasswordModalForm;
