import { bemCN } from '../../configs/bem-classname';
import './small-spinner.scss';

const loadingCn = bemCN('small-spinner');

const SmallSpinner = () => (
    <div className={loadingCn('')}></div>
);

export default SmallSpinner;
