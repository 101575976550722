import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Main } from "../pages/main-page/main-page";
import { Root } from "../pages/root-page/root-page";
import { Ui } from "../pages/ui-page/ui-page";
import { OrderPage } from "../pages/orders-page/orders-page";
import { ShipmentPage } from "../pages/shipments-page/shipments-page";
import { StocksPage } from "../pages/stocks-page/stocks-page";
import { CounterpartiesPage } from "../pages/counterparties-page/counterparties-page";
import { AssemblyPage } from "../pages/assembly-page/assembly-page";
import { OrderCardPage } from "../pages/order-card-page/order-card-page";
import { GoodsPage } from "../pages/goods-page/goods-page";
import { StockCardPage } from "../pages/stock-card-page/stock-card-page";
import { EmployeersPage } from "../pages/employeers-page/employeers-page";
import { GoodsCardPage } from "../pages/goods-card-page/goods-card-page";
import { EmployeesCardPage } from "../pages/employeers-card-page/employeers-card-page";
import { CounterpartiesCardPage } from "../pages/counterparties-card-page/counterparties-card-page";
import { ShipmentCardPage } from "../pages/shipment-card-page/shipment-card-page";
import { AssemblyCardPage } from "../pages/assembly-card-page/assembly-card-page";
import { PurchasesPage } from "../pages/purchases-page/purchases-page";
import { AcceptancePage } from "../pages/acceptance-page/acceptance-page";
import { AcceptanceCardPage } from "../pages/acceptance-card-page/acceptance-card-page";
import { MovingsPage } from "../pages/movings-page/movings-page";
import { MovingsCardPage } from "../pages/moving-card-page/moving-card-page";
import { PurchasesCard } from "../modules/purchase-module/components/purchases-card-component/purchases-card-component";
import { TasksPage } from "../pages/tasks-page/tasks-page";
import Home from "../modules/home-card-module/home-component";
import Login from "../pages/login-page/login-page";
import Register from "../pages/register-page/register-page";
import { ProfileInfoPage } from "../pages/profile-info-page/profile-info-page";
import { ProfileRatePage } from "../pages/profile-rate-page/profile-rate-page";
import { ProfileKeyPage } from "../pages/profile-key-page/profile-key-page";
import ExitForm from "../components/exit-form/exit-form";

const AppRouter = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/home", //Главная
          element: <Home />,
        },
        {
          path: "/", //вход
          element: <Main />,
        },
        {
          path: "/logout", //Главная
          element: <ExitForm />,
        },
        {
          path: "/login", //вход
          element: <Login />,
        },
        {
          path: "/register", //регистрация
          element: <Register />,
        },
        {
          path: "/profile", //Профиль
          children: [
            {
              path: "info",
              element: <ProfileInfoPage />,//общаяя
            },
            {
              path: "rate",
              element: <ProfileRatePage />,//тарифы
            },
            {
              path: "key",
              element: <ProfileKeyPage />,//ключи
            },
          ],
        },
        {
          path: "/procurement", //Закупки
          children: [
            {
              path: "purchase", //Закупки
              element: <PurchasesPage />,
            },
            {
              path: "purchase/card/:id", //Карточка закупки
              element: <PurchasesCard />,
            },
            {
              path: "acceptance", //Приемки
              element: <AcceptancePage />,
            },
            {
              path: "acceptance/card/:id", //Карточка приемки
              element: <AcceptanceCardPage />,
            },
            {
              path: "movings", //Перемещения
              element: <MovingsPage />,
            },
            {
              path: "movings/card/:id", //Карточка перемещения
              element: <MovingsCardPage />,
            },
          ],
        },
        {
          path: "/sales", //Продажи
          children: [
            {
              path: "orders", //Заказы
              element: <OrderPage />,
            },
            {
              path: "orders/card/:id", //Заказы
              element: <OrderCardPage />,
            },
            {
              path: "assembly/card/:id", //Карточка сборки
              element: <AssemblyCardPage />,
            },
            {
              path: "assembly", //Сборки заказов
              element: <AssemblyPage />,
            },
            {
              path: "shipment", //Отгрузки
              element: <ShipmentPage />,
            },
            {
              path: "shipment/card/:id", //карточка склада
              element: <ShipmentCardPage />,
            },
          ],
        },
        {
          path: "directories", //Справочники
          //element: <Main />,
          children: [
            {
              path: "goods", //Товары
              element: <GoodsPage />,
            },
            {
              path: "goods/card/:id", //Карточка товара
              element: <GoodsCardPage />,
            },
            {
              path: "goods/card/new", //новый товар
              element: <GoodsCardPage />,
            },
            {
              path: "counterparties", //Контрагены
              element: <CounterpartiesPage />,
            },
            {
              path: "counterparties/card/:id", //Контрагены
              element: <CounterpartiesCardPage />,
            },
            {
              path: "stocks", //Склады
              element: <StocksPage />,
            },
            {
              path: "stocks/card/:id", //карточка склада
              element: <StockCardPage />,
            },
            {
              path: "stocks/card/new", //новый склад
              element: <StockCardPage />,
            },
            {
              path: "stocks/card/edit", //редактировать склад
              element: <OrderCardPage />,
            },
            {
              path: "employees", //Сотрудники
              element: <EmployeersPage />,
            },
            {
              path: "employees/card/:id", //Карточка сотрудника
              element: <EmployeesCardPage />,
            },
          ],
        },
        {
          path: "/tasks", //Задачи
          element: <TasksPage />,
        },
        {
          path: "/ui",
          element: <Ui />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default AppRouter;
