import { useEffect, useState } from "react";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import "./acceptance-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import Table from "../../../../components/table-component/table-component";
import { Modal } from "../../../../components/modal-component/modal-component";
import { DocumentsModal } from "../../../../components/modal-component/document-modal-component/document-modal-component";
import { API } from "../../../../consts/api";
import Selected from "../../../../components/selected-component/selected-component";
import { SelectItem } from "../../../../core/view-models/select-item";
import { UpdateObject } from "../../../../shared/helpers/update-object";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import HeaderCard from "../../../../components/header-card-component/header-card-component";

const acceptanceCardCN = bemCN("acceptanceCard");

export const AcceptanceCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [acceptance, setAcceptance] = useState(
    location.state && location.state.acceptance
      ? location.state.acceptance
      : {
          id: 0,
          purchaseId: 0,
          number: Number(Date.now().toString().slice(-9)),
          dateTimeString: new Date().toLocaleString("de-DE", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }),
          totalPriceSelling: 0,
          totalCostPrice: 0,
          acceptanceNumberAndDateTime: "",
          purchaseNumberAndDateTime: "",
          provider: "",
          ourOrganization: "",
          storeId: 0,
          store: "",
          acceptanceStatusId: 1,
          acceptanceStatus: "",
          numberDocumentProvider: "",
          comment: "",
          authorDocumentId: 1,
          authorDocument: "",
          goods: [],
          goodsString: "",
          expenses: [],
          closed: false,
        }
  );
  const [purchases, setPurchases] = useState<any[]>([]);
  const [acceptanceStatuses, setAcceptanceStatuses] = useState<any[]>([]);
  const [expenseType, setExpenseType] = useState<any[]>([]);
  const [totalSummAcceptance, setTotalSummAcceptance] = useState(0);

  const [isShowDialog, setIsShowDialog] = useState(false);
  const [IsNew, SetIsNew] = useState(
    location.state && location.state.acceptance ? false : true
  );
  const [IsEdit, SetIsEdit] = useState(IsNew);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);

  const [isShowModalClose, setIsShowModalClose] = useState(false);
  const [isAddingExpense, setIsAddingExpense] = useState(true); //Показывает, можно ли добавить еще один вид расходов
  const [errorDelete, setErrorDelete] = useState("");
  const [isShowErrorDelete, setIsShowErrorDelete] = useState(false);
  const [errorClose, setErrorClose] = useState("");
  const [isShowErrorClose, setIsShowErrorClose] = useState(false);
  const NavHome = () => {
    navigate("/home");
  };
  //Обновление общей суммы приемки
  useEffect(() => {
    let totalSum = 0;
    acceptance.goods.map((good: any) => {
      totalSum += good.priceOneVAT * good.accepted;
    });
    setTotalSummAcceptance(totalSum);
  }, [acceptance]);

  //Загрузка доп. информации
  useEffect(() => {
    API.purchaseGetAllItemsList()
      .then((res) => {
        setPurchases([...res.data.items]);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.acceptanceStatusGetAllItemsList()
      .then((res) => {
        setAcceptanceStatuses([...res.data.items]);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
    API.expenseGetAllItemsList()
      .then((res) => {
        setExpenseType(res.data.items);
      })
      .catch((e) => {
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, []);
  const onClose = () => {
    IsNew == true
      ? API.purchaseAcceptanceAddCreate(acceptance)
          .then((res) => {
            API.purchaseAcceptanceCloseUpdate({ ...res.data })
              .then(() => {
                setAcceptance({ ...res.data, closed: true });
                setIsShowModalClose(false);
                SetIsEdit(false);
                SetIsNew(false);
              })
              .catch((e) => {
                setErrorClose(e.response.data ? e.response.data : "");
                setIsShowModalClose(false);
                setIsShowErrorClose(true);
              });
          })
          .catch((e) => {})
      : API.purchaseAcceptanceCloseUpdate({ ...acceptance })
          .then(() => {
            SetIsEdit(false);
            setAcceptance({ ...acceptance, closed: true });
            setIsShowModalClose(false);
          })
          .catch((e) => {
            setErrorClose(e.response.data ? e.response.data : "");
            setIsShowModalClose(false);
            setIsShowErrorClose(true);
          });
  };

  //Удаление приемки
  const deleteAcceptance = () => {
    setIsShowModalDelete(false);
    API.purchaseAcceptanceDelete(acceptance.id)
      .then(() => navigate("/procurement/acceptance"))
      .catch((e) => {
        setErrorDelete(e.response.data);
        setIsShowModalDelete(false);
        setIsShowErrorDelete(true);
      });
  };
  //Выбор закупки
  const updatePurchase = (v: number) => {
    let purchase = purchases.find((p) => p.id === v);
    let goods = purchase.goods;
    goods.map((g: any) => {
      g.accepted = 0;
    });
    setAcceptance({
      ...acceptance,
      store: purchase.store,
      storeId: purchase.storeId,
      purchaseId: purchase.id,
      ourOrganization: purchase.ourOrganization,
      goods: goods,
      purchaseNumberAndDateTime: purchase.number + " от " + purchase.dateTimeString,
    });
  };

  //Добавление расходов
  const addExpense = () => {
    let expenses = acceptance.expenses;
    let exs = expenseType.filter(
      (e: any) => !expenses.map((ex: any) => ex.expenseId).includes(e.id)
    );
    if (exs.length > 0) {
      let newId = Math.max(...exs.map((e: any) => e.id));
      expenses.push({
        expenseId: newId,
        name: expenseType.find((e: any) => e.id === newId).name,
        price: 0,
      });
      setAcceptance({ ...acceptance, expenses: expenses });
      exs.length === 1 && setIsAddingExpense(false);
    }
  };

  //Удаление расходов
  const deleteExpense = (id: number) => {
    let expenses = acceptance.expenses;
    expenses = expenses.filter((e: any) => e.expenseId !== id);
    setAcceptance({ ...acceptance, expenses: expenses });
    setIsAddingExpense(true);

    API.purchaseAcceptanceUpdateCostPriceCreate({
      ...acceptance,
      expenses: expenses,
    }).then((res) => {
      let newGoods = res.data.goods;
      setAcceptance({ ...acceptance, goods: newGoods, expenses: expenses });
    });
  };
  //Изменение суммы расходов
  const updatePriceExpense = (id: number, value: string | number) => {
    if (value === "") {
      value = 0;
    }
    let expenses = acceptance.expenses;
    let updateExpense = expenses.find((e: any) => e.expenseId === id);
    updateExpense.price = value;
    let newExpenses = expenses.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });
    setAcceptance({ ...acceptance, expenses: newExpenses });

    API.purchaseAcceptanceUpdateCostPriceCreate({
      ...acceptance,
      expenses: newExpenses,
    }).then((res) => {
      let newGoods = res.data.goods;
      setAcceptance({ ...acceptance, goods: newGoods, expenses: newExpenses });
    });
  };

  //Изменение названия расходов
  const updateNameExpense = (id: number, value: number) => {
    let expenses = acceptance.expenses;
    let updateExpense = expenses.find((e: any) => e.expenseId === id);
    updateExpense.expenseId = value;
    updateExpense.name = expenseType.find((e: any) => e.id === value).name;
    let newExpenses = expenses.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });
    setAcceptance({ ...acceptance, expenses: newExpenses });
  };

  //Изменение или добавление приемки
  const updateAcceptance = () => {
    if (IsNew) {
      API.purchaseAcceptanceAddCreate(acceptance).then((res) => {
        setAcceptance(res.data);
        SetIsEdit(false);
        SetIsNew(false);
        // SetIsUpdate(true);
      });
    } else {
      API.purchaseAcceptanceUpdateUpdate(acceptance).then(() => {
        SetIsEdit(false);
        SetIsNew(false);
      });
    }
  };

  //Обновление таблицы товаров
  const onUpdateRow = (index: number, object: any) => {
    let newGoods = acceptance.goods;
    newGoods[index] = object;
    API.purchaseAcceptanceUpdateCostPriceCreate({
      ...acceptance,
      goods: newGoods,
    }).then((res) => {
      let newGoods = res.data.goods;
      setAcceptance({ ...acceptance, goods: newGoods });
    });
  };

  return (
    <div className={acceptanceCardCN()}>
      <HeaderCard
        toBack={() => {
          if (IsEdit && !IsNew) {
            API.purchaseAcceptanceDetail(acceptance.id).then((res) => {
              setAcceptance(res.data);
              SetIsEdit(false);
            });
            SetIsEdit(false);
          } else {
            navigate("/procurement/acceptance");
          }
        }}
        isEdit={IsEdit}
        isNew={IsNew}
        titleText="Оформление приемки"
        setIsEdit={SetIsEdit}
        number={acceptance.number}
        date={acceptance.dateTimeString}
        statuses={acceptanceStatuses}
        currentStatusId={acceptance.acceptanceStatusId}
        currentStatus={acceptance.acceptanceStatus}
        currentWorker={acceptance.authorDocument}
        updateNubmber={(v: number) => {
          setAcceptance({ ...acceptance, number: v });
        }}
        updateDate={(v: string) => {
          setAcceptance({ ...acceptance, dateTimeString: v });
        }}
        updateStatus={(v: number) => {
          setAcceptance({
            ...acceptance,
            acceptanceStatusId: v,
            acceptanceStatus: acceptanceStatuses.find((s: any) => s.id === v)
              .name,
          });
        }}
        clickHistory={() => {}}
      />
      <div className={acceptanceCardCN("info")}>
        <div>
          {acceptance && purchases.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Закупка:"
              text={
                acceptance.purchaseNumberAndDateTime !== ""
                  ? acceptance.purchaseNumberAndDateTime
                  : ""
              }
              isActive
            />
          ) : (
            <div className={acceptanceCardCN("selected")}>
              <p className="h4">
                <span className={acceptanceCardCN("required")}>* </span>{" "}
                Закупка:
              </p>
              <Selected
                OnChange={(v) => {
                  updatePurchase(v);
                }}
                active={acceptance.purchaseId}
                items={purchases.map((item) => {
                  return new SelectItem(
                    item.id,
                    item.number + " от " + item.dateTimePurchaseString
                  );
                })}
              />
            </div>
          )}
        </div>
        <div className={acceptanceCardCN("false-input")}>
          <p className="h4">Наша организация:</p>
          <div>
            <span>{acceptance ? acceptance.ourOrganization : ""}</span>
          </div>
        </div>
        <div className={acceptanceCardCN("false-input")}>
          <p className="h4">Склад:</p>
          <div>
            <span>{acceptance ? acceptance.store : ""}</span>
          </div>
        </div>
        <div>
          <InputText
            header="№ документа поставщика:"
            text={acceptance ? acceptance.numberDocumentProvider : ""}
            isActive={!IsEdit}
            OnChange={(v) => {
              UpdateObject(acceptance, "numberDocumentProvider", v);
            }}
          />
        </div>
        <div className={acceptanceCardCN("comment")}>
          <InputText
            header="Комментарий:"
            text={acceptance ? acceptance.comment : ""}
            isActive={!IsEdit}
            OnChange={(v) => {
              setAcceptance(UpdateObject(acceptance, "comment", v));
            }}
          />
        </div>
      </div>
      <div className={acceptanceCardCN("title", ["h3"])}>Расходы</div>
      {acceptance && acceptance.expenses && acceptance.expenses.length > 0
        ? acceptance.expenses.map((item: any) => {
            return (
              <div key={item.expenseId}>
                <div className={acceptanceCardCN("info", ["expen"])}>
                  <div>
                    <InputText
                      header="Сумма:"
                      text={item ? item.price : ""}
                      isActive={!IsEdit}
                      isDigitOnly
                      OnChange={(v) => updatePriceExpense(item.expenseId, v)}
                    />
                  </div>
                  <div>
                    {acceptance && !IsEdit ? (
                      <InputText
                        header="Расходы:"
                        text={item ? item.name : ""}
                        isActive={true}
                      />
                    ) : (
                      <>
                        {" "}
                        <div className={acceptanceCardCN("selected")}>
                          <p className="h4">Расходы:</p>
                          <Selected
                            OnChange={(v) => {
                              updateNameExpense(item.expenseId, v);
                            }}
                            active={item.expenseId}
                            items={expenseType
                              .filter(
                                (expense: any) =>
                                  expense.id === item.expenseId ||
                                  !(
                                    acceptance.expenses.filter(
                                      (e: any) => e.expenseId === expense.id
                                    ).length > 0
                                  )
                              )
                              .map((exp: any) => {
                                return new SelectItem(exp.id, exp.name);
                              })}
                          />
                        </div>
                        <button onClick={() => deleteExpense(item.expenseId)}>
                          <Delete />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : null}
      {IsEdit && isAddingExpense ? (
        <div className={acceptanceCardCN("add-expense-btn")}>
          <Plus />
          <Button white text="Добавить расходы" onClick={() => addExpense()} />
        </div>
      ) : null}
      <div className={acceptanceCardCN("table_head")}>
        <div>
          <span className={acceptanceCardCN("table_head_active", ["h3"])}>
            Наименования
          </span>
          <span className={acceptanceCardCN("table_head_count", ["h3"])}>
            {acceptance && acceptance.goods.length > 0
              ? acceptance.goods.length
              : 0}
          </span>
        </div>
        <div className={acceptanceCardCN("table_head_buttons")}>
          {/* <Button id="add" text="+" circle />
          <Button
            text="Прикрепить"
            icon={<Clip />}
            text-image-left
            onClick={() => {
              setIsShowDialog(true);
            }}
          /> */}

          <p className="h2">Итого: {acceptance ? totalSummAcceptance : 0}</p>
        </div>
      </div>
      {acceptance.goods.length > 0 && (
        <Table
          isNames
          isEditable={IsEdit || IsNew}
          headersGenerate={location.state.headersGoods}
          dataGenerate={acceptance.goods}
          excludeIndexes={[0, 1, 2, 3, 4, 5]}
          isAdding={false}
          onUpdateRow={onUpdateRow}
        />
      )}
      <div className={acceptanceCardCN("buttons")}>
        <Button
          text={"Закрыть"}
          white-outline
          onClick={() => {
            if (IsEdit && !IsNew) {
              API.purchaseAcceptanceDetail(acceptance.id).then((res) => {
                setAcceptance(res.data);
                SetIsEdit(false);
              });
              SetIsEdit(false);
            } else {
              navigate("/procurement/acceptance");
            }
          }}
        />
        {IsEdit ? (
          <Button onClick={() => updateAcceptance()} text={"Сохранить"} blue />
        ) : (
          acceptance.closed == false && (
            <Button text="Редактировать" blue onClick={() => SetIsEdit(true)} />
          )
        )}
        {IsEdit && (
          <Button
            text="Завершить приемку"
            blue
            onClick={() => setIsShowModalClose(true)}
          />
        )}
        {!IsNew && (
          <Button
            image-red
            icon={<TrashBin />}
            onClick={() => setIsShowModalDelete(true)}
          />
        )}
      </div>
      <Modal
        title="Прикрепленные документы"
        isShowDialog={isShowDialog}
        setIsShowDialog={setIsShowDialog}
      >
        <DocumentsModal />
      </Modal>
      <ConfirmationModal
        onClickAgree={deleteAcceptance}
        isShowDialog={isShowModalDelete}
        setIsShowDialog={setIsShowModalDelete}
        text={
          "Вы уверены, что хотите удалить приемку заказа № " +
          acceptance?.purchaseNumberAndDateTime +
          "?"
        }
      />
      <ConfirmationModal
        onClickAgree={onClose}
        isShowDialog={isShowModalClose}
        setIsShowDialog={setIsShowModalClose}
        text={
          "Вы уверены, что хотите завершить приемку заказа № " +
          acceptance?.purchaseNumberAndDateTime +
          "? После завершения приемки изменения будут недоступны."
        }
      />
      <ConfirmationModal
        isOK
        isShowDialog={isShowErrorDelete}
        setIsShowDialog={setIsShowErrorDelete}
        text={errorDelete && errorDelete}
      />
      <ConfirmationModal
        isOK
        isShowDialog={isShowErrorClose}
        setIsShowDialog={setIsShowErrorClose}
        text={errorClose && errorClose}
      />
    </div>
  );
};
