import { bemCN } from "../../configs/bem-classname";
import ProfileKey from "../../modules/profile-module/components/profile-key/profile-key";
import ProfileTariffs from "../../modules/profile-module/components/profile-tariffs/profile-tariffs";
import "./profile-key-page.scss"

const rootCN = bemCN("ProfileKeyPage");

export const ProfileKeyPage = () => {
  return (
    <div className={rootCN()}>
      <ProfileKey/>
    </div>
  );
};