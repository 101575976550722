import { ReactNode, memo, useEffect, useState } from "react";
import { bemCN } from "../../configs/bem-classname";
import "./input-text.scss";

const inputCN = bemCN("input-text");

export const availableButtons = [
  "icon-right",
  "icon-left",
  "header-edit",
] as const;

type InputTextProps = {
  [icon in (typeof availableButtons)[number]]?: boolean;
} & {
  placeholder?: string;
  icon?: ReactNode;
  header?: string;
  id?: string;
  OnChange?: (value: string) => void;
  isActive?: boolean;
  text?: string;
  isRequired?: boolean;
  isDigitOnly?: boolean;
  isTextArea?: boolean;
  maxLengh?: number;
  minLengh?: number;
};

export const InputText = (props: InputTextProps) => {
  let {
    placeholder,
    icon,
    header,
    id,
    OnChange,
    isActive,
    text,
    isRequired,
    isDigitOnly,
    isTextArea,
    maxLengh,
    minLengh,
  } = props;
  const [input, SetText] = useState(text);
  useEffect(() => {
    SetText(text);
  }, [text]);
  const onChange = (value: string) => {
    if (
      (isDigitOnly && maxLengh && value.length > maxLengh) ||
      (minLengh && value.length < minLengh)
    ) {
      return;
    }
    if(isDigitOnly){
      value = value.replace(/[^\d]/g, '')
    }
    SetText(value);
    OnChange && OnChange(value);
  };
  const adding_class = availableButtons.find((i) => props[i]) ?? "";
  let className = inputCN(adding_class);
  return (
    <div className={className} id={id}>
      {header && (
        <p className="h4">
          {isRequired ? <span className={inputCN("required")}>* </span> : ""}
          {header}
        </p>
      )}
      <div>
        {icon}
        {isTextArea ? (
          <textarea
            readOnly={isActive}
            onChange={(e) => onChange(e.target.value)}
            value={input}
            className={inputCN("text", ["h4"])}
            placeholder={placeholder}
            name={id}
            id={id}
            maxLength={maxLengh}
            minLength={minLengh}
          />
        ) : (
          <input
            type={"text"}
            pattern={isDigitOnly ? "\d":"*"}
            readOnly={isActive}
            onChange={(e) => onChange(e.target.value)}
            value={input}
            className={inputCN("text", ["h4"])}
            placeholder={placeholder}
            name={id}
            id={id}
            maxLength={maxLengh}
            minLength={minLengh}
          />
        )}
      </div>
    </div>
  );
};
