import "./header-card-component.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC } from "react";
import { ReactComponent as ArrowBack } from "../../shared/images/svg/arrow-back.svg";
import { ReactComponent as History } from "../../shared/images/svg/active-history.svg";

import { InputText } from "../../ui/input-text/input-text";
import { InputMask } from "primereact/inputmask";
import Selected from "../selected-component/selected-component";
import { SelectItem } from "../../core/view-models/select-item";
import { Button } from "../../ui/button/button";
import InputTime from "../../ui/input-time/input-time";

type HeaderCardProps = {
  toBack(): void;
  isNew: boolean;
  titleText: string;
  isEdit: boolean;
  setIsEdit: (isEdit: boolean) => void;
  number: number;
  updateNubmber: (v: number) => void;
  date: string;
  updateDate: (v: string) => void;
  statuses: any[];
  currentStatusId: number;
  currentStatus: string;
  updateStatus: (v: number) => void;
  currentWorker: string;
  clickHistory: () => void;
};

const HeaderCard: FC<HeaderCardProps> = ({
  toBack,
  isEdit,
  isNew,
  titleText,
  number,
  updateNubmber,
  date,
  updateDate,
  statuses,
  currentStatusId,
  currentStatus,
  updateStatus,
  currentWorker,
  clickHistory,
}) => {
  const headerCardCN = bemCN("header-card");

  return (
    <div className={headerCardCN()}>
      <div>
        <button onClick={() => toBack()}>
          <ArrowBack />
        </button>
        <h2>{titleText} №</h2>
        {isEdit ? (
          <>
            <InputText
              header-edit
              OnChange={(v) => {
                v !== "" ? updateNubmber(parseInt(v)) : updateNubmber(0);
              }}
              text={number.toString()}
            />
            <h2>от</h2>
            <InputTime
              bottom-line
              date={date}
              onChange={(v: any) => {
                v && updateDate(v.toString());
              }}
            />
          </>
        ) : (
          <>
            {" "}
            <h2>{number + " от " + date}</h2>
          </>
        )}
      </div>
      <div>
        {/* <Button image-white icon={<History />} onClick={clickHistory} /> */}

        {isEdit ? (
          <Selected
            active={currentStatusId}
            items={statuses.map((st: any) => {
              return new SelectItem(st.id, st.name);
            })}
            OnChange={(v) => updateStatus(v)}
          />
        ) : (
          <span className="h4">{!isNew ? currentStatus : "Статус"}</span>
        )}

        <span className="h2">{!isNew ? currentWorker : "Автор документа"}</span>
      </div>
    </div>
  );
};

export default HeaderCard;
