import { bemCN } from "../../configs/bem-classname";
import ProfileTariffs from "../../modules/profile-module/components/profile-tariffs/profile-tariffs";
import "./profile-rate-page.scss"

const rootCN = bemCN("ProfileRatePage");

export const ProfileRatePage = () => {
  return (
    <div className={rootCN()}>
      <ProfileTariffs/>
    </div>
  );
};