import React, { MouseEvent, FC, ReactNode } from 'react';
import "./Modal.scss"
import { bemCN } from '../../../configs/bem-classname';

interface ModalProps {
  backgroundClick?: Function;
  children?: ReactNode;
}

const mainCN = bemCN("Modal");
const Modal: FC<ModalProps> = ({ backgroundClick, children }) => {
  const closeModal = (e: MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget !== e.target) return;

    if (typeof backgroundClick === 'function') backgroundClick();
  };

  return (
    <div className={mainCN()} onClick={(e) => closeModal(e)}>
      {children}
    </div>
  );
};

export default Modal;
