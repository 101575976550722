import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { bemCN } from "../../configs/bem-classname";
import "./root-page.scss";
import SearchHeader from "../../components/header-component/header-component";
import SideBar from "../../components/sidebar-component/sidebar-component";
import TopMenu from "../../components/top-menu-component/top-menu-component";
import { useEffect, useState } from "react";
import { HomeCardPage } from "../home-card-page/home-card-page";
import { useAuth } from "../../shared/hooks/useAuth";
import { navLinks } from "../../consts/navbar-links";
import { profileLinks } from "../../consts/profile-links";
import { cookieStorage } from "../../configs/cookie-storage";
import AuthProvider from "../../components/auth-provider/auth-provider";
import { API } from "../../consts/api";

const rootCN = bemCN("root");
function RequireAuth({ children }: any) {
  //console.log(isAuth)
  return localStorage.getItem("auth") === "true" ? (
    children
  ) : (
    <Navigate to="/home" replace />
  );
}

export const Root = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [isShowMenu, setIsShowMenu] = useState(false);
  useEffect(() => {
    if (
      location.pathname == "/home" ||
      location.pathname == "/login" ||
      location.pathname == "/register"
    ) {
      isShowMenu == true && setIsShowMenu(false);
    } else {
      API.authGetUserIdList()
      .then(() => {
        isShowMenu == false && setIsShowMenu(true);
        let c = cookieStorage.getCookie("IsPaidUser");
        if (((c && c === "false") || !c) && location.pathname != "/profile/rate") {
         // return navigate("/profile/rate");
        }
      })
      .catch((e) => {
        return navigate("/home");
      });
     
    }
  }, [location, navigate]);
  return (
    <div className={rootCN()}>
      {isShowMenu == true ? (
        <RequireAuth>
          <SideBar />
          <div>
            <SearchHeader />
            <TopMenu
              links={
                location.pathname.includes("profile") ? profileLinks : navLinks
              }
            />
            <Outlet />
          </div>
        </RequireAuth>
      ) : (
        <Outlet />
      )}
    </div>
  );
};
