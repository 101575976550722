import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as Filter } from "../../../../shared/images/svg/filter.svg";

import { Button } from "../../../../ui/button/button";
import "./purchases-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { FC, useEffect, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { SideFilterPanel } from "../../../filter-module";
import { PurchasesFilter } from "../purchases-filter-component/purchases-filter-component";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../../../components/loading-component/loading-component";
import { API } from "../../../../consts/api";
import { SearchInTable } from "../../../../shared/helpers/search-in-table";
import { FilterPurchaseTableModel } from "../../../../core/api/data-contracts";

type PurchasesProps = {
  id?: string;
};

export interface FilterPurchase extends FilterPurchaseTableModel {
  providerAll?: any[];
  purchaseStatusAll?: any[];
}

export const Purchases: FC<PurchasesProps> = ({ id }) => {
  const purchasesCN = bemCN("purchases");
  const location = useLocation();
  const navigate = useNavigate();
  const onClickRow = (id: number) => {
    navigate("card/" + id, {
      replace: true,
      state: {
        purchase: DataList.find((e) => e.id === id),
        headers: HeadersPurchase,
      },
    });
  };

  const [isShowFilter, setIsShowFilter] = useState(false);
  const [DataList, SetDataList] = useState<any[]>([]);

  const [Headers, SetHeaders] = useState<any>();
  const [HeadersPurchase, SetHeadersPurchase] = useState<any>();
  const [IsLoading, SetIsLoading] = useState(false);
  const [Error, SetError] = useState<Error>();
  const [finder, setFinder] = useState<string | undefined>();
  const [date, setDate] = useState<any[]>();
  const [filters, setFilters] = useState<FilterPurchase>({});
  const NavHome = () => {
    navigate("/home");
  };
  useEffect(() => {
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.purchaseGetAllItemsList({ finder, start, end })
      .then((res) => {
        if (!Headers) {
          SetHeaders(res.data.nameFields);
          let table = res.data.items;
          table && initFilters(table);
        }
        let items = location?.state?.filter
          ? res.data.items.filter(
              (f: any) => f.purchaseStatus == location?.state?.filter
            )
          : res.data.items;
        SetDataList([...items]);
        if (!HeadersPurchase) {
          SetHeadersPurchase(res.data.nameGoodFields);
        }
        SetIsLoading(true);
      })
      .catch((e) => {
        SetError(e);
        SetIsLoading(false);
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, [finder, date]);

  const initFilters = (table: any) => {
    filters.items = table;
    filters.totalCostPrice = {};
    filters.totalPriceSelling = {};
    filters.daysComingStore = {};
    filters.totalPriceSelling!.min = Math.min(
      ...table.map((t: any) => t.totalPriceSelling)
    );
    filters.totalPriceSelling!.max = Math.max(
      ...table.map((t: any) => t.totalPriceSelling)
    );
    filters.totalCostPrice!.min = Math.min(
      ...table.map((t: any) => t.totalCostPrice)
    );
    filters.totalCostPrice!.max = Math.max(
      ...table.map((t: any) => t.totalCostPrice)
    );
    filters.daysComingStore!.min = Math.min(
      ...table.map((t: any) => t.daysComingStore)
    );
    filters.daysComingStore!.max = Math.max(
      ...table.map((t: any) => t.daysComingStore)
    );

    filters.provider = Array.from(new Set(table.map((t: any) => t.provider)));
    filters.purchaseStatus = Array.from(
      new Set(table.map((t: any) => t.purchaseStatus))
    );
    filters.providerAll = Array.from(
      new Set(table.map((t: any) => t.provider))
    ).map((e) => {
      return { subjectName: e, select: true };
    });
    filters.purchaseStatusAll = Array.from(
      new Set(table.map((t: any) => t.purchaseStatus))
    ).map((e) => {
      return { subjectName: e, select: true };
    });
  };
  const onClickSort = (header: string, isDescending: boolean) => {
    DataList &&
      API.purchaseSortTablePurchaseCreate(DataList, {
        header,
        isDescending,
      }).then((res) => {
        SetDataList(res.data);
      });
  };

  const onSaveFilters = (f: FilterPurchase) => {
    DataList &&
      API.purchaseFilterTableCreate(f).then((res) => {
        setIsShowFilter(false);
        SetDataList([...res.data]);
      });
  };

  const onCancelFilters = () => {
    setIsShowFilter(false);
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.purchaseGetAllItemsList({ finder, start, end })
      .then((res) => {
        let table = res.data.items;
        table && initFilters(table);
        if (!Headers) {
          SetHeaders(res.data.nameFields);
        }
        SetDataList([...res.data.items]);
        if (!HeadersPurchase) {
          SetHeadersPurchase(res.data.nameGoodFields);
        }
        SetIsLoading(true);
      })
      .catch((e) => {
        SetError(e);
        SetIsLoading(false);
      });
  };
  if (Error) {
    throw Error;
  }
  if (!IsLoading) {
    return <Loading color="dark" />;
  }
  return (
    <div id={id} className={purchasesCN()}>
      {isShowFilter ? (
        <SideFilterPanel
          isShow={isShowFilter}
          setIsShow={setIsShowFilter}
          onSave={() => onSaveFilters(filters)}
          onCancel={onCancelFilters}
        >
          <PurchasesFilter filters={filters} onChange={setFilters} />
        </SideFilterPanel>
      ) : null}
      <div className={purchasesCN("topPanel")}>
        {/* <RadioCalendar /> */}
        <Calendar ChangeDate={setDate} />
      </div>
      <div className={purchasesCN("title", ["h1"])}>Закупки</div>
      <div className={purchasesCN("buttons")}>
        <Button
          text-image-left
          text="Фильтры"
          onClick={() => setIsShowFilter(!isShowFilter)}
          icon={<Filter />}
        />
        <Button
          text-image-left
          text="Добавить закупку"
          icon={<PlusCircle />}
          onClick={() =>
            navigate("card/new", {
              replace: true,
              state: {
                headers: HeadersPurchase,
              },
            })
          }
        />
        {/* <ExportButton></ExportButton> */}
        <InputText
          placeholder="Поиск по закупке"
          OnChange={setFinder}
          icon={<Search />}
          icon-left
        />
      </div>
      <Table
        onClickSort={onClickSort}
        isSortable
        onClickRow={onClickRow}
        dataGenerate={DataList}
        headersGenerate={Headers}
      />
    </div>
  );
};
