import React, { FC } from 'react';
import styles from './details-modal-form.scss';
import ModalForm, { ModalFormAbstract } from '../../ModalForm/ModalForm';
import InputField from '../../../ui/input-field/input-field';


interface ProfileInfoModalDetailProps extends ModalFormAbstract {}

const ProfileInfoModalDetail: FC<ProfileInfoModalDetailProps> = ({
  backgroundClick,
  closeModal,
  onClickButton,
}) => (
  <ModalForm
    className={styles.ProfileInfoModalDetail}
    backgroundClick={backgroundClick}
    title="Реквизиты организации"
    buttonText="Сохранить"
    closeModal={closeModal}
    onClickButton={onClickButton}
  >
    <div className="ModalForm__inputs ModalForm__inputs-mb">
      <InputField label="Название организации*"></InputField>
      <InputField label="ИНН"></InputField>
      <InputField label="КПП"></InputField>
      <InputField label="ОГРН / ОГРНИП*"></InputField>
      <InputField label="Юридический адрес*"></InputField>
    </div>
  </ModalForm>
);

export default ProfileInfoModalDetail;
