import { bemCN } from "../../configs/bem-classname";
import './header-component.scss';
import { ReactComponent as Search } from "../../shared/images/svg/search.svg";
import { ReactComponent as  Favorite} from "../../shared/images/svg/favorite.svg";
import { ReactComponent as Profile } from "../../shared/images/svg/profile.svg";
import { ReactComponent as Notice } from "../../shared/images/svg/notice.svg";
import { Link } from "react-router-dom";

const headerCn = bemCN('header'); 
 
const SearchHeader = () => { 
 
  return ( 
    <header className={headerCn()}> 
     <div className={headerCn('tariff')}>
      <span className="h3">Базовый тариф</span>
      {/* <Search />
      <input className="h3" placeholder="Поиск..."></input> */}
     </div>
    <div className={headerCn('toolbar')}>
      <Notice/>
      <Favorite/>
      <Link to="/profile/info" className={headerCn('user')}>
        {/* {photo===null ? <ProfileDefualt/> : <img src={'data:image/jpeg;base64, ' + photo} />} */}
    
        <Profile/>
      </Link>
    </div>
    </header> 
  ); 
}; 
 
export default SearchHeader;