import { FC, useState } from "react";
import "./edit-phone-modal-form.scss";
import { bemCN } from "../../../../../../configs/bem-classname";
import { Modal } from "../../../../../../components/modal-component/modal-component";
import InputFieldPhone from "../../../../../../ui/input-field-phone/InputFieldPhone";
import { Button } from "../../../../../../ui/button/button";
import { API } from "../../../../../../consts/api";

interface EditPhoneModalFormProps {
  isShowModal: boolean;
  setIsShowModal(isShowModal: boolean): void;
  user: any;
  setUser(user: any): void;
}
const editPhoneModalFormCN = bemCN("edit-phone-modal-form");

const EditPhoneModalForm: FC<EditPhoneModalFormProps> = ({
  isShowModal,
  setIsShowModal,
  user,
  setUser,
}) => {
  const [newPhone, setNewPhone] = useState("");
  const savePhone = () => {
    API.profileSetPhoneNumberCreate({
      phoneNumber: newPhone.replace(/\s+/g, ""),
    }).then(() => {
      setUser({ ...user, phoneNumber: newPhone });
      setIsShowModal(false);
    });
  };

  return (
    <Modal
      title={"Изменение номера телефона"}
      isShowDialog={isShowModal}
      setIsShowDialog={setIsShowModal}
    >
      <div className={editPhoneModalFormCN()}>
        <InputFieldPhone
          onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
            setNewPhone(v.currentTarget.value);
          }}
          className={editPhoneModalFormCN("input")}
          label="Введите номер телефона"
        />
        <Button blue text="Сохранить изменения" onClick={() => savePhone()} />
      </div>
    </Modal>
  );
};

export default EditPhoneModalForm;
