import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import { ReactComponent as Filter } from "../../../../shared/images/svg/filter.svg";
import "./acceptances-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { FC, useEffect, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { useNavigate } from "react-router-dom";
import { SideFilterPanel } from "../../../filter-module";
import { AcceptanceFilter } from "../acceptance-filter-component/acceptance-filter-component";
import { API } from "../../../../consts/api";
import Loading from "../../../../components/loading-component/loading-component";
import { SearchInTable } from "../../../../shared/helpers/search-in-table";
import { FilterPurchaseAcceptanceTableModel } from "../../../../core/api/data-contracts";

type AcceptancesProps = {
  id?: string;
};
export interface FilterAcceptance extends FilterPurchaseAcceptanceTableModel {
  providerAll?: any[];
  acceptanceStatusAll?: any[];
}
export const Acceptances: FC<AcceptancesProps> = ({ id }) => {
  const acceptancesCN = bemCN("acceptances");
  const navigate = useNavigate();

  const [acceptances, setAcceptances] = useState<any[]>([]);

  const [headers, setHeaders] = useState<any>();
  const [headersGoods, setHeadersGood] = useState<any>();
  const [finder, setFinder] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [date, setDate] = useState<any[]>();
  const [filters, setFilters] = useState<FilterAcceptance>({});

  const NavHome = () => {
    navigate("/home");
  };
  const onClickRow = (id: number) => {
    navigate("card/" + id, {
      replace: true,
      state: {
        acceptance: acceptances.find((e) => e.id === id),
        headersGoods: headersGoods,
      },
    });
  };
  const onClickAdd = () => {
    navigate("card/new", {
      replace: true,
      state: { headersGoods: headersGoods },
    });
  };

  const onClickSort = (header: string, isDescending: boolean) => {
    acceptances &&
      API.purchaseAcceptanceSortTableAcceptanceCreate(acceptances, {
        header,
        isDescending,
      })
        .then((res) => {
          setAcceptances(res.data);
        })
        .catch((e) => {
          if (e.request.status == 401) {
            NavHome();
          }
        });
  };

  useEffect(() => {
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.purchaseAcceptanceGetAllItemsList({ finder, start, end })
      .then((res) => {
        if (!headers) {
          setHeaders(res.data.nameFields);
          let table = res.data.items;
          table && initFilters(table);
        }
        setAcceptances([...res.data.items]);
        if (!headersGoods) setHeadersGood(res.data.nameGoodFields);
        setIsLoading(true);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
        if (e.request.status == 401) {
          NavHome();
        }
      });
  }, [finder, date]);

  const initFilters = (table: any) => {
    filters.items = table;
    filters.totalCostPrice = {};
    filters.totalPriceSelling = {};
    filters.totalPriceSelling!.min = Math.min(
      ...table.map((t: any) => t.totalPriceSelling)
    );
    filters.totalPriceSelling!.max = Math.max(
      ...table.map((t: any) => t.totalPriceSelling)
    );
    filters.totalCostPrice!.min = Math.min(
      ...table.map((t: any) => t.totalCostPrice)
    );
    filters.totalCostPrice!.max = Math.max(
      ...table.map((t: any) => t.totalCostPrice)
    );

    filters.provider = Array.from(new Set(table.map((t: any) => t.provider)));
    filters.acceptanceStatus = Array.from(
      new Set(table.map((t: any) => t.acceptanceStatus))
    );
    filters.providerAll = Array.from(
      new Set(table.map((t: any) => t.provider))
    ).map((e) => {
      return { subjectName: e, select: true };
    });
    filters.acceptanceStatusAll = Array.from(
      new Set(table.map((t: any) => t.acceptanceStatus))
    ).map((e) => {
      return { subjectName: e, select: true };
    });
  };

  const onSaveFilters = (f: FilterAcceptance) => {
    acceptances &&
      API.purchaseAcceptanceFilterTableCreate(f)
        .then((res) => {
          setIsShowFilter(false);
          setAcceptances([...res.data]);
        })
        .catch((e) => {
          if (e.request.status == 401) {
            NavHome();
          }
        });
  };
  const onCancelFilters = () => {
    setIsShowFilter(false);
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.purchaseAcceptanceGetAllItemsList({ finder, start, end })
      .then((res) => {
        let table = res.data.items;
        table && initFilters(table);
        if (!headers) {
          setHeaders(res.data.nameFields);
        }
        setAcceptances([...res.data.items]);
        if (!headersGoods) {
          setHeadersGood(res.data.nameGoodFields);
        }
        setIsLoading(true);
      })
      .catch((e) => {
        setError(e);
        setIsLoading(false);
        if (e.request.status == 401) {
          NavHome();
        }
      });
  };

  if (error) {
    throw Error;
  }
  if (!isLoading) {
    return <Loading color="dark" />;
  }

  return (
    <div id={id} className={acceptancesCN()}>
      {isShowFilter ? (
        <SideFilterPanel
          isShow={isShowFilter}
          setIsShow={setIsShowFilter}
          onSave={() => onSaveFilters(filters)}
          onCancel={onCancelFilters}
        >
          <AcceptanceFilter filters={filters} onChange={setFilters} />
        </SideFilterPanel>
      ) : null}
      <div className={acceptancesCN("topPanel")}>
        {/* <RadioCalendar /> */}
        <Calendar ChangeDate={setDate} />
      </div>
      <div className={acceptancesCN("title", ["h1"])}>Приемки</div>
      <div className={acceptancesCN("buttons")}>
        <Button
          text-image-left
          text="Фильтры"
          onClick={() => setIsShowFilter(!isShowFilter)}
          icon={<Filter />}
        />
        <Button
          text-image-left
          text="Добавить приемку"
          icon={<PlusCircle />}
          onClick={onClickAdd}
        />
        {/* <ExportButton></ExportButton> */}
        <InputText
          placeholder="Поиск по приемке"
          OnChange={setFinder}
          icon={<Search />}
          icon-left
        />
      </div>
      <Table
        isSortable
        onClickSort={onClickSort}
        onClickRow={onClickRow}
        headersGenerate={headers}
        dataGenerate={acceptances}
      />
    </div>
  );
};
